import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Refresh } from '@material-ui/icons';
import ReactGA from "react-ga4";

import AppCard from "../AppCard/AppCard";

import { CardDataContext } from "./../../context";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import userHelper from "../../utils/helpers/userHelper";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  item: {},
}));

//Will contain all app card components
function CardContainer(props) {
  const classes = useStyles();

  const cardDatas = useContext(CardDataContext);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { trackPageView } = useMatomo()

   async function init(){
      setLoading(true);
      await userHelper.initData(dispatch);
      setLoading(false);
   }

   React.useEffect(()=>{
    trackPageView({
      documentTitle:"App list",
      href:"/"
    });
    ReactGA.send({ hitType: "pageview", page: "/", title: "App list" });
   },[])

  return (
    <>
    {cardDatas.datas.length===0 && (
      <Box>
        <Grid container style={{height:"88.8vh",flexDirection:"column"}} justifyContent="center" alignItems="center">
          <Typography style={{fontSize:22}}>
            {`La liste est vide`}
          </Typography>
          {!loading && (<IconButton onClick={init}>
            <Refresh/>
          </IconButton>)}
          {loading && (
            <CircularProgress size={25} />
          )}
        </Grid>
      </Box>
    )}
    {cardDatas.datas.length>0 && (<Grid className={classes.root} container justify="space-evenly">
      {cardDatas.datas.map((card, i) => {
        return (
        <Grid
          className={classes.item}
          key={`card_${i}`}
          item
          xs={12}
          sm={4}
          md={3}
          lg={3}
          xl={3}
        >
          <AppCard
            imageUrl={card.logo_url}
            name={card.name}
            category={card.products}
            app_repos={card?.prod_url || card.app_repos}
            bgColor={card.bg_color}
            cancelColor={card.cancel_color}
            orientation={card.orientation}
          />
        </Grid>
      )})}
    </Grid>)}
    </>
  );
}

export default CardContainer;

import React from "react";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Slide from "@mui/material/Slide";
import { Button, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import userService from "../services/userService";
import gsap from 'gsap';
import { colorPrimary } from "../utils/colors";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      <div></div>
    </Slide>);
});

const Splash = styled.div`
    height: 100vh;
    top: 0;
    width: 100%;
    max-width:100%;
    z-index: 9999;
    background: #fff;
    position: fixed;
    transition: background cubic-bezier(0.445, 0.05, 0.55, 0.95) 1.5s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Loader = styled.div`
    display:flex;
    align-items:center;
    justify-content:cente;
`;


export const SplashScreen: React.FC<{
  open: boolean; setOpen: any;
}> = ({
  open = true, setOpen }) => {

    const [loading, setLoading] = React.useState(false);
    const [screen, setScreen] = React.useState<"small"|"middle"|"large">("large");

    React.useEffect(() => {
      onResize();
      setLoading(true);
    }, [])

    window.addEventListener("resize",(ev)=>{
      onResize();
    })

    function onResize(){
      const { innerWidth } = window;
      if(innerWidth<760){
        setScreen("small");
      }else if(innerWidth<1200){
        setScreen("middle");
      }else{
        setScreen("large")
      }
    }




    React.useEffect(() => {
      if (open) {
        window.document.body.style.height = "100vh";
        window.document.body.style.overflow = "hidden";
      } else {
        window.document.body.style.height = "auto";
        window.document.body.style.overflow = "auto";
      }
    }, [open]);

    return (
      <React.Fragment>

        {open && (
          <Splash>
            {loading && (<div className='loading' style={{ position: "absolute", right: 16, bottom: 16, display: "none" }}>
              <Box>
                <CircularProgress size={25} />
              </Box>
            </div>)}
            {process.env.REACT_APP_ENV == 'rec' && (
              <div style={{ position: "absolute", alignSelf: "center", bottom: 20 }}>
                <Typography>
                  {`Version de test`}
                </Typography>
              </div>
            )}
            <Loader>
              <img className="splash--img" style={{
                maxWidth:screen==="small"?"75px":screen==="middle"?"100px":"150px"
              }} src={require('../assets/icon.png')} alt="" />
              <div style={{ position: "absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)" }}>
                <CircularProgress 
                  thickness={0.3}
                  size={
                    screen==="small"?120:screen==="middle"?200:250
                  }
                  sx={{
                    color:colorPrimary,
                  }}
                />
              </div>
            </Loader>

          </Splash>)}
      </React.Fragment>
    );
  }

import React, { useState } from "react";

import Header from "../Header/Header";
import CardContainer from "../CardContainer/CardContainer";

import { CardDataContext } from "../../context";

import { AllCardDatas } from "../../datas";
import { getCountryCode } from "../Utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { isEmpty, toLower } from "lodash";
import { setGames } from "../../redux/gameReducer";
import userHelper from "../../utils/helpers/userHelper";

/* The Home container is a component that contain the header and the cardContainer */
function HomeContainer(props:any) {
  const countryCode = window.localStorage.getItem("country")
    ? getCountryCode(window.localStorage.getItem("country"))
    : "cd";

  const cardDatas = AllCardDatas.filter((data) => data.country === countryCode);

  const [datas, setData] = useState<any[]>([]);

  const games = useSelector((state:RootState)=>state.game.all);
  const gameSelecteds = useSelector((state:RootState)=>state.game.list);
  const country = useSelector((state:RootState)=>state.country.current);
  const product = useSelector((state:RootState)=>state.product.current);
  const level = useSelector((state:RootState)=>state.level.current);
  const search = useSelector((state:RootState)=>state.game.search);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(()=>{
    setData(gameSelecteds)
  },[gameSelecteds])

  React.useEffect(()=>{
    init();
  },[level,product,country,search])

  async function init(){
    window.localStorage.setItem("orientation", "land");
    let items:any[] = games;
    const value = search;
    if(isEmpty(value)){
      dispatch(setGames(games))
    }else{
      items = items.filter(p=>toLower(p.name).includes(toLower(value)))
    }
    if(country?.id>0){
      items = items.filter((p:any)=>{
        const list:any[] = p.countries ?? [];
        const index = list.findIndex(q=>q.id===country.id);
        return index!==-1;
      })
    }
    if(level?.id>0){
      items = items.filter((p:any)=>{
        const list:any[] = p.levels ?? [];
        const index = list.findIndex(q=>q.id===level.id);
        return index!==-1;
      })
    }
    if(product?.id>0){
      items = items.filter((p:any)=>{
        const list:any[] = p.products?? [];
        const index = list.findIndex(q=>q.id===product.id);
        return index!==-1;
      })
    }
    dispatch(setGames(items))
  }

  return (
    <CardDataContext.Provider value={{ datas: datas, setData: setData }}>
      <Header load={loading} />
      <CardContainer />
    </CardDataContext.Provider>
  );
}

export default HomeContainer;

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import { useMatomo } from '@datapunt/matomo-tracker-react'
import ReactGA from "react-ga4";

import Fade from "@material-ui/core/Grow";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(2),
    },
    justifyContent: "center",
  },
  card: {
    padding: theme.spacing(1),
  },
  cardArea: {
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    width: "70%",
  },
  cardContent: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  chip: {
    width: "60%",
    backgroundColor: "#e97b00",
    color: "white",
  },
}));

function AppCard(props) {
  const classes = useStyles();
  const navigation = useNavigate();
  const { trackEvent } = useMatomo()

  const onClick = (src, bgColor, cancelColor, orientation,name) => {
    //console.log("CardItem clicked ", src);
    trackEvent({action:"click-app",category:"click",name:name ?? "app-url",value:src});
    ReactGA.event({
      category: "click",
      action: "click-app",
      label: name, // optional
      value: src, // optional, must be a number
      //nonInteraction: true, // optional, true/false
      //transport: "xhr", // optional, beacon/xhr/image
    });

    window.localStorage.setItem("src", src);
    window.localStorage.setItem("app-name", name);
    window.localStorage.setItem("bgColor", bgColor);
    window.localStorage.setItem("cancelColor", cancelColor);
    window.localStorage.setItem("orientation", orientation || "land");
    navigation('play',{replace:true});
  };

  return (
    <Fade in={true}>
      <div className={classes.root}>
        <Card
          className={classes.card}
          onClick={() =>
            onClick(
              props.app_repos,
              props.bgColor,
              props.cancelColor,
              props.orientation,
              props.name,
            )
          }
        >
          <CardActionArea
            //href="/play"
            className={classes.cardArea}
          >
            <CardMedia
              className={classes.cardMedia}
              component="img"
              alt={`${props.name}`}
              image={props.imageUrl}
              title={`${props.name}`}
            />
          </CardActionArea>
        </Card>
      </div>
    </Fade>
  );
}

export default AppCard;

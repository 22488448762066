import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface adminData {
  current: any;
  list: any[];
  all: any[];
}

const initialState: adminData = {
  current: {},
  all: [],
  list: [],
};

export const adminSlice = createSlice({
  name: "admins",
  initialState,
  reducers: {
    setAdmin: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setAdmins: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    setAllAdmins: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAllAdmins, setAdmin, setAdmins } = adminSlice.actions;
export default adminSlice.reducer;

import { app_logo } from "./assets";

export const AllCardDatas = [
  //< Applis RDC >
  {
    imageUrl: app_logo.activExam,
    name: "ActivExam",
    category: ["lettres", "math"],
    app_repos: "https://privateapps.africatik.com/activexam-primaire/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lekki,
    name: "Lekki",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lekki/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.voambolana,
    name: "Voambolana",
    category: ["lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/voambolana/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.labyrinthe_oasis,
    name: "Labyrinthe Oasis",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/labyrinthe-oasis",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.ecologie,
    name: "Ecologie",
    category: ["tc", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/ecologie/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.inclusion_financiere,
    name: "Inclusion Financière",
    category: ["tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/inclusion/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.network,
    name: "Network",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/network",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kaluun,
    name: "Kaluun",
    category: ["lettres", "math", "logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/kaluun/",
    bgColor: "#2CE1FF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.africoloriage,
    name: "Africoloriage",
    category: ["logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/afriColoriage/",
    bgColor: "#00A5DF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.anatomie,
    name: "Anatomie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/anatomie_v1.3.0",
    bgColor: "#000000",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.makuta,
    name: "Makuta",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/makuta/",
    bgColor: "#78BB6E",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },

  {
    imageUrl: app_logo.tanga,
    name: "Tanga",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-apps/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_2_logo,
    name: "Tanga 2",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-deux/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_3_logo,
    name: "Tanga 3",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-trois/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_maternelle,
    name: "Mutshi M",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutshiMaternelle",
    bgColor: "#EEEEEE",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [0],
    orientation: "both",
  },
  {
    imageUrl: app_logo.kinPuzzle,
    name: "Kin Puzzle 2",
    category: ["logique", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/kin-puzzle-rdc",
    bgColor: "#B4AFB9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bimuma,
    name: "Bimuma",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bimuma",
    bgColor: "#BBBEB5",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.jdl,
    name: "Jeu de Lettres",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/jdl",
    bgColor: "#90EE90",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.geo,
    name: "Géographie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/geographie_afrique/",
    bgColor: "#DEAA87",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_primaire,
    name: "Mutshi P",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutshiPrimaire",
    bgColor: "#EEEEEE",
    level: [1, 2, 3, 4, 5, 6],
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    orientation: "land",
  },
  {
    imageUrl: app_logo.bongo,
    name: "Bongo",
    category: ["logique", "lettres", "math", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bongo",
    bgColor: "#CF6C35",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chimie,
    name: "Chimie Les mélanges",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chimie",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.raqmi,
    name: "Raqmi",
    category: ["sciences", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/raqmi",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [3, 4, 5, 6, 7, 8],
    orientation: "both",
  },
  {
    imageUrl: app_logo.kinzazi,
    name: "Kinzazi",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/kinzazi-apps",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.chenza,
    name: "Chenza",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chenza-fruits-apps",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.mutuka,
    name: "Mutuka",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutuka-apps",
    bgColor: "#52D1E7",
    cancelColor: "#CC0022",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.alpha_app_video,
    name: "Alpha App Vidéo",
    category: ["alphabetisation", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/alphaappvideo/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite1,
    name: "Alphabetisation - 1 - L'alphabet",
    category: ["alphabetisation", "tc", "lettres"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ1/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite2,
    name: "Alphabetisation - 2 - Les sons",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ2/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite3,
    name: "Alphabetisation - 3 - Les nombres",
    category: ["alphabetisation", "math", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ3/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite4,
    name: "Alphabetisation - 4 - Les salutations",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ4/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite5,
    name: "Alphabetisation - 5 - Le marché",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ5/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite6,
    name: "Alphabetisation - 2 - L'hygiène",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ6/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite7,
    name: "Alphabetisation - 2 - La famille",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ7/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite8,
    name: "Alphabetisation - 2 - La météo",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ8/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rdc",
    level: [8],
    orientation: "land",
  },
  // Madagascar
  {
    imageUrl: app_logo.network,
    name: "Network",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/network",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_2_logo,
    name: "Tanga 2",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-deux/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.alpha_app_video,
    name: "Alpha App Vidéo",
    category: ["alphabetisation", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/alphaappvideo/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_3_logo,
    name: "Tanga 3",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-trois/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lekki,
    name: "Lekki",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lekki/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.voambolana,
    name: "Voambolana",
    category: ["lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/voambolana/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.inclusion_financiere,
    name: "Inclusion Financière",
    category: ["tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/inclusion/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.raqmi,
    name: "Raqmi",
    category: ["sciences", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/raqmi",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [3, 4, 5, 6, 7, 8],
    orientation: "both",
  },
  {
    imageUrl: app_logo.labyrinthe_oasis,
    name: "Labyrinthe Oasis",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/labyrinthe-oasis",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.ecologie,
    name: "Ecologie",
    category: ["tc", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/ecologie/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.activExam,
    name: "ActivExam",
    category: ["lettres", "math"],
    app_repos: "https://privateapps.africatik.com/activexam-primaire/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kaluun,
    name: "Kaluun",
    category: ["lettres", "math", "logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/kaluun/",
    bgColor: "#2CE1FF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.africoloriage,
    name: "Africoloriage",
    category: ["logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/afriColoriage/",
    bgColor: "#00A5DF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.geo,
    name: "Géographie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/geographie_afrique/",
    bgColor: "#DEAA87",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.vola,
    name: "Vola",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/vola/",
    bgColor: "#78BB6E",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.anatomie,
    name: "Anatomie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/anatomie_v1.3.0",
    bgColor: "#000000",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_sud_logo,
    name: "Tanga Sud",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-sud-v1.0.0/",
    bgColor: "#000000",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bimuma,
    name: "Bimuma",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bimuma",
    bgColor: "#BBBEB5",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.jdl,
    name: "Jeu de Lettres",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/jdl",
    bgColor: "#90EE90",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chimie_sud,
    name: "Chimie Les mélanges Sud",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chimie-sud-v1.1.6",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [3, 4, 5, 6],
    orientation: "land",
  },

  {
    imageUrl: app_logo.kinzazi_sud,
    name: "Kinzazi Sud",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/kinzazi-sud-v1.3.0",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_primaire_sud,
    name: "Mutshi Primaire Sud",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutshi-primaire-sud",
    bgColor: "#EEEEEE",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_maternelle_sud,
    name: "Mutshi Maternelle Sud",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/mutshi-maternelle-sud",
    bgColor: "#EEEEEE",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [0],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutuka,
    name: "Mutuka",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutuka-apps",
    bgColor: "#52D1E7",
    cancelColor: "#CC0022",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mada_puzzle,
    name: "Mada Puzzle",
    category: ["logique", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mada-puzzle-2",
    bgColor: "#B4AFB9",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bongo_sud,
    name: "Bongo Sud",
    category: ["logique", "lettres", "math", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bongo_sud",
    bgColor: "#CF6C35",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite1,
    name: "Alphabetisation - 1 - L'alphabet",
    category: ["alphabetisation", "tc", "lettres"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ1/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite2,
    name: "Alphabetisation - 2 - Les sons",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ2/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite3,
    name: "Alphabetisation - 3 - Les nombres",
    category: ["alphabetisation", "math", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ3/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite4,
    name: "Alphabetisation - 4 - Les salutations",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ4/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite5,
    name: "Alphabetisation - 5 - Le marché",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ5/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite6,
    name: "Alphabetisation - 2 - L'hygiène",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ6/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite7,
    name: "Alphabetisation - 2 - La famille",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ7/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite8,
    name: "Alphabetisation - 2 - La météo",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ8/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "madagascar",
    level: [8],
    orientation: "land",
  },

  // Senegal
  {
    imageUrl: app_logo.labyrinthe_oasis,
    name: "Labyrinthe Oasis",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/labyrinthe-oasis",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_2_logo,
    name: "Tanga 2",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-deux/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.alpha_app_video,
    name: "Alpha App Vidéo",
    category: ["alphabetisation", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/alphaappvideo/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_3_logo,
    name: "Tanga 3",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-trois/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lekki,
    name: "Lekki",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lekki/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.voambolana,
    name: "Voambolana",
    category: ["lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/voambolana/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.network,
    name: "Network",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/network",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.activExam,
    name: "ActivExam",
    category: ["lettres", "math"],
    app_repos: "https://privateapps.africatik.com/activexam-primaire/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.raqmi,
    name: "Raqmi",
    category: ["sciences", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/raqmi",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [3, 4, 5, 6, 7, 8],
    orientation: "both",
  },
  {
    imageUrl: app_logo.inclusion_financiere,
    name: "Inclusion Financière",
    category: ["tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/inclusion/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.ecologie,
    name: "Ecologie",
    category: ["tc", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/ecologie/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kaluun,
    name: "Kaluun",
    category: ["lettres", "math", "logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/kaluun/",
    bgColor: "#2CE1FF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.africoloriage,
    name: "Africoloriage",
    category: ["logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/afriColoriage/",
    bgColor: "#00A5DF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.koopar,
    name: "Koopar",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/koopar/",
    bgColor: "#78BB6E",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_ouest,
    name: "Tanga Ouest",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-ouest-v1.0.0/",
    bgColor: "#000000",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.geo,
    name: "Géographie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/geographie_afrique/",
    bgColor: "#DEAA87",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chimie_ouest,
    name: "Chimie Les mélanges Ouest",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chimie-ouest-v1.1.6",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kinzazi_ouest,
    name: "Kinzazi Ouest",
    category: ["sciences"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/kinzazi-ouest-v1.3.0",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_primaire_ouest,
    name: "Mutshi Primaire Ouest",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/mutshi-primaire-ouest",
    bgColor: "#EEEEEE",
    cancelColor: "#000000",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_maternelle_ouest,
    name: "Mutshi Maternelle Ouest",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/mutshi-maternelle-ouest",
    bgColor: "#EEEEEE",
    cancelColor: "#000000",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [0],
    orientation: "land",
  },
  {
    imageUrl: app_logo.dakar_puzzle,
    name: "Dakar Puzzle 2",
    category: ["logique", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/dakar-puzzle-2",
    bgColor: "#B4AFB9",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bongo_ouest,
    name: "Bongo Ouest",
    category: ["logique", "lettres", "math", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bongo_ouest",
    bgColor: "#CF6C35",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutuka,
    name: "Mutuka",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutuka-apps",
    bgColor: "#52D1E7",
    cancelColor: "#CC0022",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bimuma,
    name: "Bimuma",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bimuma",
    bgColor: "#BBBEB5",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.anatomie,
    name: "Anatomie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/anatomie_v1.3.0",
    bgColor: "#000000",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.jdl,
    name: "Jeu de Lettres",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/jdl",
    bgColor: "#90EE90",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite1,
    name: "Alphabetisation - 1 - L'alphabet",
    category: ["alphabetisation", "tc", "lettres"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ1/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite2,
    name: "Alphabetisation - 2 - Les sons",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ2/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite3,
    name: "Alphabetisation - 3 - Les nombres",
    category: ["alphabetisation", "math", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ3/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite4,
    name: "Alphabetisation - 4 - Les salutations",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ4/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite5,
    name: "Alphabetisation - 5 - Le marché",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ5/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite6,
    name: "Alphabetisation - 2 - L'hygiène",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ6/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite7,
    name: "Alphabetisation - 2 - La famille",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ7/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite8,
    name: "Alphabetisation - 2 - La météo",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ8/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "senegal",
    level: [8],
    orientation: "land",
  },

  //Djibouti
  {
    imageUrl: app_logo.network,
    name: "Network",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/network",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.alpha_app_video,
    name: "Alpha App Vidéo",
    category: ["alphabetisation", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/alphaappvideo/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lekki,
    name: "Lekki",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lekki/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_2_logo,
    name: "Tanga 2",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-deux/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_3_logo,
    name: "Tanga 3",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-trois/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.voambolana,
    name: "Voambolana",
    category: ["lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/voambolana/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.raqmi,
    name: "Raqmi",
    category: ["sciences", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/raqmi",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [3, 4, 5, 6, 7, 8],
    orientation: "both",
  },
  {
    imageUrl: app_logo.inclusion_financiere,
    name: "Inclusion Financière",
    category: ["tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/inclusion/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.labyrinthe_oasis,
    name: "Labyrinthe Oasis",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/labyrinthe-oasis",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.ecologie,
    name: "Ecologie",
    category: ["tc", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/ecologie/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.activExam,
    name: "ActivExam",
    category: ["lettres", "math"],
    app_repos: "https://privateapps.africatik.com/activexam-primaire/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kaluun,
    name: "Kaluun",
    category: ["lettres", "math", "logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/kaluun/",
    bgColor: "#2CE1FF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.africoloriage,
    name: "Africoloriage",
    category: ["logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/afriColoriage/",
    bgColor: "#00A5DF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.geo,
    name: "Géographie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/geographie_afrique/",
    bgColor: "#DEAA87",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_est,
    name: "Tanga Est",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-est-v1.0.0/",
    bgColor: "#000000",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lacagta,
    name: "Lacagta",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lacagta",
    bgColor: "#78BB6E",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chimie_est,
    name: "Chimie Les mélanges Est",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chimie-est-v1.1.6",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kinzazi_est,
    name: "Kinzazi Est",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/kinzazi-est-v1.3.0",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_primaire_est,
    name: "Mutshi Primaire Est",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutshi-primaire-est",
    bgColor: "#EEEEEE",
    cancelColor: "#000000",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_maternelle_est,
    name: "Mutshi Maternelle Est",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/mutshi-maternelle-est",
    bgColor: "#EEEEEE",
    cancelColor: "#000000",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [0],
    orientation: "land",
  },
  {
    imageUrl: app_logo.djibou_puzzle,
    name: "Djibout Puzzle 2",
    category: ["logique", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/djibouti-puzzle-2",
    bgColor: "#B4AFB9",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bongo_est,
    name: "Bongo Est",
    category: ["logique", "lettres", "math", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bongo_est",
    bgColor: "#CF6C35",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutuka,
    name: "Mutuka",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutuka-apps",
    bgColor: "#52D1E7",
    cancelColor: "#CC0022",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bimuma,
    name: "Bimuma",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bimuma",
    bgColor: "#BBBEB5",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.anatomie,
    name: "Anatomie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/anatomie_v1.3.0",
    bgColor: "#000000",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.jdl,
    name: "Jeu de Lettres",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/jdl",
    bgColor: "#90EE90",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite1,
    name: "Alphabetisation - 1 - L'alphabet",
    category: ["alphabetisation", "tc", "lettres"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ1/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite2,
    name: "Alphabetisation - 2 - Les sons",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ2/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite3,
    name: "Alphabetisation - 3 - Les nombres",
    category: ["alphabetisation", "math", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ3/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite4,
    name: "Alphabetisation - 4 - Les salutations",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ4/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite5,
    name: "Alphabetisation - 5 - Le marché",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ5/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite6,
    name: "Alphabetisation - 2 - L'hygiène",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ6/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite7,
    name: "Alphabetisation - 2 - La famille",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ7/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite8,
    name: "Alphabetisation - 2 - La météo",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ8/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "djibouti",
    level: [8],
    orientation: "land",
  },

  // CAMEROUN
  {
    imageUrl: app_logo.activExam,
    name: "ActivExam",
    category: ["lettres", "math"],
    app_repos: "https://privateapps.africatik.com/activexam-primaire/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.alpha_app_video,
    name: "Alpha App Vidéo",
    category: ["alphabetisation", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/alphaappvideo/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.africoloriage,
    name: "Africoloriage",
    category: ["logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/afriColoriage/",
    bgColor: "#00A5DF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite1,
    name: "Alphabetisation - 1 - L'alphabet",
    category: ["alphabetisation", "tc", "lettres"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ1/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite2,
    name: "Alphabetisation - 2 - Les sons",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ2/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite3,
    name: "Alphabetisation - 3 - Les nombres",
    category: ["alphabetisation", "math", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ3/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite4,
    name: "Alphabetisation - 4 - Les salutations",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ4/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite5,
    name: "Alphabetisation - 5 - Le marché",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ5/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite6,
    name: "Alphabetisation - 2 - L'hygiène",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ6/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite7,
    name: "Alphabetisation - 2 - La famille",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ7/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite8,
    name: "Alphabetisation - 2 - La météo",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ8/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.anatomie,
    name: "Anatomie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/anatomie_v1.3.0",
    bgColor: "#000000",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bimuma,
    name: "Bimuma",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bimuma",
    bgColor: "#BBBEB5",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bongo,
    name: "Bongo",
    category: ["logique", "math", "lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bongo",
    bgColor: "#CF6C35",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chenza_ouest,
    name: "Chenza Ouest",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chenza-ouest",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.chimie,
    name: "Chimie Les mélanges",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chimie",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.ecologie,
    name: "Ecologie",
    category: ["tc", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/ecologie/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.geo,
    name: "Géographie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/geographie_afrique/",
    bgColor: "#DEAA87",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.inclusion_financiere,
    name: "Inclusion Financière",
    category: ["tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/inclusion/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.jdl,
    name: "Jeu de Lettres",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/jdl",
    bgColor: "#90EE90",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kaluun,
    name: "Kaluun",
    category: ["lettres", "math", "logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/kaluun/",
    bgColor: "#2CE1FF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kinzazi,
    name: "Kinzazi",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/kinzazi-apps",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.koopar,
    name: "Koopar",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/koopar/",
    bgColor: "#78BB6E",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.labyrinthe_oasis,
    name: "Labyrinthe Oasis",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/labyrinthe-oasis",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lekki,
    name: "Lekki",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lekki/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_maternelle,
    name: "Mutshi M",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutshiMaternelle",
    bgColor: "#EEEEEE",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [0],
    orientation: "both",
  },
  {
    imageUrl: app_logo.mutshi_primaire,
    name: "Mutshi Primaire",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutshiPrimaire",
    bgColor: "#EEEEEE",
    level: [1, 2, 3, 4, 5, 6],
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutuka,
    name: "Mutuka",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutuka-apps",
    bgColor: "#52D1E7",
    cancelColor: "#CC0022",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.network,
    name: "Network",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/network",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga,
    name: "Tanga",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-apps/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.voambolana,
    name: "Voambolana",
    category: ["lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/voambolana/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [0, 1, 2],
    orientation: "land",
  },

  {
    imageUrl: app_logo.raqmi,
    name: "Raqmi",
    category: ["sciences", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/raqmi",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [3, 4, 5, 6, 7, 8],
    orientation: "both",
  },
  {
    imageUrl: app_logo.yaounde_puzzle,
    name: "Yaoundé Puzzle 2",
    category: ["logique", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/yaoundepuzzle/",
    bgColor: "#B4AFB9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_2_logo,
    name: "Tanga 2",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-deux/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_3_logo,
    name: "Tanga 3",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-trois/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cameroun",
    level: [0, 1, 2, 3],
    orientation: "land",
  },

  // CONGO
  {
    imageUrl: app_logo.activExam,
    name: "ActivExam",
    category: ["lettres", "math"],
    app_repos: "https://privateapps.africatik.com/activexam-primaire/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.africoloriage,
    name: "Africoloriage",
    category: ["logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/afriColoriage/",
    bgColor: "#00A5DF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.alpha_app_video,
    name: "Alpha App Vidéo",
    category: ["alphabetisation", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/alphaappvideo/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite1,
    name: "Alphabetisation - 1 - L'alphabet",
    category: ["alphabetisation", "tc", "lettres"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ1/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite2,
    name: "Alphabetisation - 2 - Les sons",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ2/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite3,
    name: "Alphabetisation - 3 - Les nombres",
    category: ["alphabetisation", "math", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ3/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite4,
    name: "Alphabetisation - 4 - Les salutations",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ4/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite5,
    name: "Alphabetisation - 5 - Le marché",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ5/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite6,
    name: "Alphabetisation - 2 - L'hygiène",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ6/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite7,
    name: "Alphabetisation - 2 - La famille",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ7/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite8,
    name: "Alphabetisation - 2 - La météo",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ8/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.anatomie,
    name: "Anatomie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/anatomie_v1.3.0",
    bgColor: "#000000",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bimuma,
    name: "Bimuma",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bimuma",
    bgColor: "#BBBEB5",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chenza,
    name: "Chenza",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chenza-fruits-apps",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.chimie,
    name: "Chimie Les mélanges",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chimie",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.ecologie,
    name: "Ecologie",
    category: ["tc", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/ecologie/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.geo,
    name: "Géographie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/geographie_afrique/",
    bgColor: "#DEAA87",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.inclusion_financiere,
    name: "Inclusion Financière",
    category: ["tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/inclusion/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.jdl,
    name: "Jeu de Lettres",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/jdl",
    bgColor: "#90EE90",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kaluun,
    name: "Kaluun",
    category: ["lettres", "math", "logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/kaluun/",
    bgColor: "#2CE1FF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kinPuzzle,
    name: "Kin Puzzle 2",
    category: ["logique", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/kin-puzzle-rdc",
    bgColor: "#B4AFB9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kinzazi,
    name: "Kinzazi",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/kinzazi-apps",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.koopar,
    name: "Koopar",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/koopar/",
    bgColor: "#78BB6E",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.labyrinthe_oasis,
    name: "Labyrinthe Oasis",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/labyrinthe-oasis",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lekki,
    name: "Lekki",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lekki/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_maternelle,
    name: "Mutshi M",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutshiMaternelle",
    bgColor: "#EEEEEE",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [0],
    orientation: "both",
  },
  {
    imageUrl: app_logo.mutshi_primaire,
    name: "Mutshi Primaire",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutshiPrimaire",
    bgColor: "#EEEEEE",
    level: [1, 2, 3, 4, 5, 6],
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutuka,
    name: "Mutuka",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutuka-apps",
    bgColor: "#52D1E7",
    cancelColor: "#CC0022",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.network,
    name: "Network",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/network",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga,
    name: "Tanga",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-apps/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.voambolana,
    name: "Voambolana",
    category: ["lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/voambolana/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [0, 1, 2],
    orientation: "land",
  },

  {
    imageUrl: app_logo.raqmi,
    name: "Raqmi",
    category: ["sciences", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/raqmi",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [3, 4, 5, 6, 7, 8],
    orientation: "both",
  },
  {
    imageUrl: app_logo.tanga_2_logo,
    name: "Tanga 2",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-deux/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_3_logo,
    name: "Tanga 3",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-trois/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "congo",
    level: [0, 1, 2, 3],
    orientation: "land",
  },

  // COTE D'IVOIRE
  {
    imageUrl: app_logo.abidjan_puzzle,
    name: "Abidjan Puzzle 2",
    category: ["logique", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/abidjanpuzzle/",
    bgColor: "#B4AFB9",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.alpha_app_video,
    name: "Alpha App Vidéo",
    category: ["alphabetisation", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/alphaappvideo/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.activExam,
    name: "ActivExam",
    category: ["lettres", "math"],
    app_repos: "https://privateapps.africatik.com/activexam-primaire/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.africoloriage,
    name: "Africoloriage",
    category: ["logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/afriColoriage/",
    bgColor: "#00A5DF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite1,
    name: "Alphabetisation - 1 - L'alphabet",
    category: ["alphabetisation", "tc", "lettres"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ1/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite2,
    name: "Alphabetisation - 2 - Les sons",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ2/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite3,
    name: "Alphabetisation - 3 - Les nombres",
    category: ["alphabetisation", "math", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ3/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite4,
    name: "Alphabetisation - 4 - Les salutations",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ4/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite5,
    name: "Alphabetisation - 5 - Le marché",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ5/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite6,
    name: "Alphabetisation - 2 - L'hygiène",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ6/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite7,
    name: "Alphabetisation - 2 - La famille",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ7/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite8,
    name: "Alphabetisation - 2 - La météo",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ8/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.anatomie,
    name: "Anatomie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/anatomie_v1.3.0",
    bgColor: "#000000",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bimuma,
    name: "Bimuma",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bimuma",
    bgColor: "#BBBEB5",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bongo_ouest,
    name: "Bongo Ouest",
    category: ["logique", "math", "lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bongo_ouest",
    bgColor: "#CF6C35",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chenza_ouest,
    name: "Chenza Ouest",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chenza-ouest",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.chimie_ouest,
    name: "Chimie Les mélanges Ouest",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chimie-ouest-v1.1.6",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.ecologie,
    name: "Ecologie",
    category: ["tc", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/ecologie/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.geo,
    name: "Géographie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/geographie_afrique/",
    bgColor: "#DEAA87",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.inclusion_financiere,
    name: "Inclusion Financière",
    category: ["tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/inclusion/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.jdl,
    name: "Jeu de Lettres",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/jdl",
    bgColor: "#90EE90",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kaluun,
    name: "Kaluun",
    category: ["lettres", "math", "logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/kaluun/",
    bgColor: "#2CE1FF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kinzazi_ouest,
    name: "Kinzazi Ouest",
    category: ["sciences"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/kinzazi-ouest-v1.3.0",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.koopar,
    name: "Koopar",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/koopar/",
    bgColor: "#78BB6E",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.labyrinthe_oasis,
    name: "Labyrinthe Oasis",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/labyrinthe-oasis",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lekki,
    name: "Lekki",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lekki/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_maternelle_ouest,
    name: "Mutshi Maternelle Ouest",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/mutshi-maternelle-ouest",
    bgColor: "#EEEEEE",
    cancelColor: "#000000",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [0],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_primaire_ouest,
    name: "Mutshi Primaire Ouest",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/mutshi-primaire-ouest",
    bgColor: "#EEEEEE",
    cancelColor: "#000000",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutuka,
    name: "Mutuka",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutuka-apps",
    bgColor: "#52D1E7",
    cancelColor: "#CC0022",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.network,
    name: "Network",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/network",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_ouest,
    name: "Tanga Ouest",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-ouest-v1.0.0/",
    bgColor: "#000000",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.voambolana,
    name: "Voambolana",
    category: ["lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/voambolana/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [0, 1, 2],
    orientation: "land",
  },

  {
    imageUrl: app_logo.raqmi,
    name: "Raqmi",
    category: ["sciences", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/raqmi",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [3, 4, 5, 6, 7, 8],
    orientation: "both",
  },
  {
    imageUrl: app_logo.tanga_2_logo,
    name: "Tanga 2",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-deux/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_3_logo,
    name: "Tanga 3",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-trois/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "cote_divoire",
    level: [0, 1, 2, 3],
    orientation: "land",
  },

  // ETHIOPIE
  {
    imageUrl: app_logo.network,
    name: "Network",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/network",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lekki,
    name: "Lekki",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lekki/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.alpha_app_video,
    name: "Alpha App Vidéo",
    category: ["alphabetisation", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/alphaappvideo/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.voambolana,
    name: "Voambolana",
    category: ["lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/voambolana/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.raqmi,
    name: "Raqmi",
    category: ["sciences", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/raqmi",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [3, 4, 5, 6, 7, 8],
    orientation: "both",
  },
  {
    imageUrl: app_logo.labyrinthe_oasis,
    name: "Labyrinthe Oasis",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/labyrinthe-oasis",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.ecologie,
    name: "Ecologie",
    category: ["tc", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/ecologie/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.inclusion_financiere,
    name: "Inclusion Financière",
    category: ["tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/inclusion/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.activExam,
    name: "ActivExam",
    category: ["lettres", "math"],
    app_repos: "https://privateapps.africatik.com/activexam-primaire/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kaluun,
    name: "Kaluun",
    category: ["lettres", "math", "logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/kaluun/",
    bgColor: "#2CE1FF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.africoloriage,
    name: "Africoloriage",
    category: ["logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/afriColoriage/",
    bgColor: "#00A5DF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.geo,
    name: "Géographie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/geographie_afrique/",
    bgColor: "#DEAA87",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_est,
    name: "Tanga Est",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-est-v1.0.0/",
    bgColor: "#000000",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chimie_est,
    name: "Chimie Les mélanges Est",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chimie-est-v1.1.6",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kinzazi_est,
    name: "Kinzazi Est",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/kinzazi-est-v1.3.0",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_primaire_est,
    name: "Mutshi Primaire Est",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutshi-primaire-est",
    bgColor: "#EEEEEE",
    cancelColor: "#000000",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_maternelle_est,
    name: "Mutshi Maternelle Est",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/mutshi-maternelle-est",
    bgColor: "#EEEEEE",
    cancelColor: "#000000",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [0],
    orientation: "land",
  },
  {
    imageUrl: app_logo.djibou_puzzle,
    name: "Djibout Puzzle 2",
    category: ["logique", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/djibouti-puzzle-2",
    bgColor: "#B4AFB9",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bongo_est,
    name: "Bongo Est",
    category: ["logique", "math", "lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bongo_est",
    bgColor: "#CF6C35",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lacagta,
    name: "Lacagta",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lacagta",
    bgColor: "#78BB6E",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutuka,
    name: "Mutuka",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutuka-apps",
    bgColor: "#52D1E7",
    cancelColor: "#CC0022",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bimuma,
    name: "Bimuma",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bimuma",
    bgColor: "#BBBEB5",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.anatomie,
    name: "Anatomie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/anatomie_v1.3.0",
    bgColor: "#000000",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.jdl,
    name: "Jeu de Lettres",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/jdl",
    bgColor: "#90EE90",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite1,
    name: "Alphabetisation - 1 - L'alphabet",
    category: ["alphabetisation", "tc", "lettres"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ1/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite2,
    name: "Alphabetisation - 2 - Les sons",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ2/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite3,
    name: "Alphabetisation - 3 - Les nombres",
    category: ["alphabetisation", "math", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ3/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite4,
    name: "Alphabetisation - 4 - Les salutations",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ4/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite5,
    name: "Alphabetisation - 5 - Le marché",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ5/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite6,
    name: "Alphabetisation - 2 - L'hygiène",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ6/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite7,
    name: "Alphabetisation - 2 - La famille",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ7/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite8,
    name: "Alphabetisation - 2 - La météo",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ8/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_2_logo,
    name: "Tanga 2",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-deux/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_3_logo,
    name: "Tanga 3",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-trois/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "ethiopie",
    level: [0, 1, 2, 3],
    orientation: "land",
  },

  // GABON
  {
    imageUrl: app_logo.network,
    name: "Network",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/network",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lekki,
    name: "Lekki",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lekki/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.alpha_app_video,
    name: "Alpha App Vidéo",
    category: ["alphabetisation", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/alphaappvideo/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.voambolana,
    name: "Voambolana",
    category: ["lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/voambolana/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.labyrinthe_oasis,
    name: "Labyrinthe Oasis",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/labyrinthe-oasis",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.ecologie,
    name: "Ecologie",
    category: ["tc", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/ecologie/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.inclusion_financiere,
    name: "Inclusion Financière",
    category: ["tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/inclusion/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.activExam,
    name: "ActivExam",
    category: ["lettres", "math"],
    app_repos: "https://privateapps.africatik.com/activexam-primaire/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kaluun,
    name: "Kaluun",
    category: ["lettres", "math", "logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/kaluun/",
    bgColor: "#2CE1FF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.raqmi,
    name: "Raqmi",
    category: ["sciences", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/raqmi",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [3, 4, 5, 6, 7, 8],
    orientation: "both",
  },
  {
    imageUrl: app_logo.africoloriage,
    name: "Africoloriage",
    category: ["logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/afriColoriage/",
    bgColor: "#00A5DF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.koopar,
    name: "Koopar",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/koopar/",
    bgColor: "#78BB6E",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.geo,
    name: "Géographie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/geographie_afrique/",
    bgColor: "#DEAA87",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga,
    name: "Tanga",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-apps/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bongo,
    name: "Bongo",
    category: ["logique", "math", "lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bongo",
    bgColor: "#CF6C35",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutuka,
    name: "Mutuka",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutuka-apps",
    bgColor: "#52D1E7",
    cancelColor: "#CC0022",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chenza_ouest,
    name: "Chenza Ouest",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chenza-ouest",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.kinzazi,
    name: "Kinzazi",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/kinzazi-apps",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.mutshi_primaire,
    name: "Mutshi P",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutshiPrimaire",
    bgColor: "#EEEEEE",
    level: [1, 2, 3, 4, 5, 6],
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_maternelle,
    name: "Mutshi M",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutshiMaternelle",
    bgColor: "#EEEEEE",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [0],
    orientation: "both",
  },
  {
    imageUrl: app_logo.chimie,
    name: "Chimie Les mélanges",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chimie",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.kinPuzzle,
    name: "Kin Puzzle 2",
    category: ["logique", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/kin-puzzle-rdc",
    bgColor: "#B4AFB9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bimuma,
    name: "Bimuma",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bimuma",
    bgColor: "#BBBEB5",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.anatomie,
    name: "Anatomie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/anatomie_v1.3.0",
    bgColor: "#000000",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.jdl,
    name: "Jeu de Lettres",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/jdl",
    bgColor: "#90EE90",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite1,
    name: "Alphabetisation - 1 - L'alphabet",
    category: ["alphabetisation", "tc", "lettres"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ1/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite2,
    name: "Alphabetisation - 2 - Les sons",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ2/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite3,
    name: "Alphabetisation - 3 - Les nombres",
    category: ["alphabetisation", "math", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ3/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite4,
    name: "Alphabetisation - 4 - Les salutations",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ4/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite5,
    name: "Alphabetisation - 5 - Le marché",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ5/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite6,
    name: "Alphabetisation - 2 - L'hygiène",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ6/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite7,
    name: "Alphabetisation - 2 - La famille",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ7/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite8,
    name: "Alphabetisation - 2 - La météo",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ8/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_2_logo,
    name: "Tanga 2",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-deux/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_3_logo,
    name: "Tanga 3",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-trois/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "gabon",
    level: [0, 1, 2, 3],
    orientation: "land",
  },

  // GUINEE
  {
    imageUrl: app_logo.network,
    name: "Network",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/network",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lekki,
    name: "Lekki",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lekki/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.alpha_app_video,
    name: "Alpha App Vidéo",
    category: ["alphabetisation", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/alphaappvideo/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.voambolana,
    name: "Voambolana",
    category: ["lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/voambolana/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.labyrinthe_oasis,
    name: "Labyrinthe Oasis",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/labyrinthe-oasis",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.ecologie,
    name: "Ecologie",
    category: ["tc", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/ecologie/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.inclusion_financiere,
    name: "Inclusion Financière",
    category: ["tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/inclusion/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.raqmi,
    name: "Raqmi",
    category: ["sciences", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/raqmi",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [3, 4, 5, 6, 7, 8],
    orientation: "both",
  },
  {
    imageUrl: app_logo.activExam,
    name: "ActivExam",
    category: ["lettres", "math"],
    app_repos: "https://privateapps.africatik.com/activexam-primaire/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chenza_ouest,
    name: "Chenza Ouest",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chenza-ouest",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.kaluun,
    name: "Kaluun",
    category: ["lettres", "math", "logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/kaluun/",
    bgColor: "#2CE1FF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.africoloriage,
    name: "Africoloriage",
    category: ["logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/afriColoriage/",
    bgColor: "#00A5DF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_ouest,
    name: "Tanga Ouest",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-ouest-v1.0.0/",
    bgColor: "#000000",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.geo,
    name: "Géographie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/geographie_afrique/",
    bgColor: "#DEAA87",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chimie_ouest,
    name: "Chimie Les mélanges Ouest",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chimie-ouest-v1.1.6",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kinzazi_ouest,
    name: "Kinzazi Ouest",
    category: ["sciences"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/kinzazi-ouest-v1.3.0",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_primaire_ouest,
    name: "Mutshi Primaire Ouest",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/mutshi-primaire-ouest",
    bgColor: "#EEEEEE",
    cancelColor: "#000000",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_maternelle_ouest,
    name: "Mutshi Maternelle Ouest",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/mutshi-maternelle-ouest",
    bgColor: "#EEEEEE",
    cancelColor: "#000000",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [0],
    orientation: "land",
  },
  {
    imageUrl: app_logo.conakry_puzzle,
    name: "Conakry Puzzle 2",
    category: ["logique", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/conakrypuzzle/",
    bgColor: "#B4AFB9",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bongo_ouest,
    name: "Bongo Ouest",
    category: ["logique", "math", "lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bongo_ouest",
    bgColor: "#CF6C35",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.koopar,
    name: "Koopar",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/koopar/",
    bgColor: "#78BB6E",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutuka,
    name: "Mutuka",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutuka-apps",
    bgColor: "#52D1E7",
    cancelColor: "#CC0022",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bimuma,
    name: "Bimuma",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bimuma",
    bgColor: "#BBBEB5",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.anatomie,
    name: "Anatomie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/anatomie_v1.3.0",
    bgColor: "#000000",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.jdl,
    name: "Jeu de Lettres",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/jdl",
    bgColor: "#90EE90",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite1,
    name: "Alphabetisation - 1 - L'alphabet",
    category: ["alphabetisation", "tc", "lettres"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ1/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite2,
    name: "Alphabetisation - 2 - Les sons",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ2/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite3,
    name: "Alphabetisation - 3 - Les nombres",
    category: ["alphabetisation", "math", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ3/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite4,
    name: "Alphabetisation - 4 - Les salutations",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ4/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite5,
    name: "Alphabetisation - 5 - Le marché",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ5/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite6,
    name: "Alphabetisation - 2 - L'hygiène",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ6/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite7,
    name: "Alphabetisation - 2 - La famille",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ7/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite8,
    name: "Alphabetisation - 2 - La météo",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ8/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_2_logo,
    name: "Tanga 2",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-deux/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_3_logo,
    name: "Tanga 3",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-trois/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "guinee",
    level: [0, 1, 2, 3],
    orientation: "land",
  },

  // KENYA
  {
    imageUrl: app_logo.network,
    name: "Network",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/network",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lekki,
    name: "Lekki",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lekki/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.voambolana,
    name: "Voambolana",
    category: ["lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/voambolana/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.alpha_app_video,
    name: "Alpha App Vidéo",
    category: ["alphabetisation", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/alphaappvideo/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.labyrinthe_oasis,
    name: "Labyrinthe Oasis",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/labyrinthe-oasis",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.inclusion_financiere,
    name: "Inclusion Financière",
    category: ["tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/inclusion/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.ecologie,
    name: "Ecologie",
    category: ["tc", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/ecologie/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.raqmi,
    name: "Raqmi",
    category: ["sciences", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/raqmi",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [3, 4, 5, 6, 7, 8],
    orientation: "both",
  },
  {
    imageUrl: app_logo.activExam,
    name: "ActivExam",
    category: ["lettres", "math"],
    app_repos: "https://privateapps.africatik.com/activexam-primaire/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kaluun,
    name: "Kaluun",
    category: ["lettres", "math", "logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/kaluun/",
    bgColor: "#2CE1FF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.africoloriage,
    name: "Africoloriage",
    category: ["logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/afriColoriage/",
    bgColor: "#00A5DF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_est,
    name: "Tanga Est",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-est-v1.0.0/",
    bgColor: "#000000",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.geo,
    name: "Géographie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/geographie_afrique/",
    bgColor: "#DEAA87",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chimie_est,
    name: "Chimie Les mélanges Est",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chimie-est-v1.1.6",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_primaire_est,
    name: "Mutshi Primaire Est",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutshi-primaire-est",
    bgColor: "#EEEEEE",
    cancelColor: "#000000",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_maternelle_est,
    name: "Mutshi Maternelle Est",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/mutshi-maternelle-est",
    bgColor: "#EEEEEE",
    cancelColor: "#000000",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [0],
    orientation: "land",
  },
  {
    imageUrl: app_logo.djibou_puzzle,
    name: "Djibout Puzzle 2",
    category: ["logique", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/djibouti-puzzle-2",
    bgColor: "#B4AFB9",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bongo_est,
    name: "Bongo Est",
    category: ["logique", "math", "lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bongo_est",
    bgColor: "#CF6C35",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lacagta,
    name: "Lacagta",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lacagta",
    bgColor: "#78BB6E",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutuka,
    name: "Mutuka",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutuka-apps",
    bgColor: "#52D1E7",
    cancelColor: "#CC0022",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bimuma,
    name: "Bimuma",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bimuma",
    bgColor: "#BBBEB5",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.anatomie,
    name: "Anatomie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/anatomie_v1.3.0",
    bgColor: "#000000",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.jdl,
    name: "Jeu de Lettres",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/jdl",
    bgColor: "#90EE90",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite1,
    name: "Alphabetisation - 1 - L'alphabet",
    category: ["alphabetisation", "tc", "lettres"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ1/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite2,
    name: "Alphabetisation - 2 - Les sons",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ2/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite3,
    name: "Alphabetisation - 3 - Les nombres",
    category: ["alphabetisation", "math", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ3/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite4,
    name: "Alphabetisation - 4 - Les salutations",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ4/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite5,
    name: "Alphabetisation - 5 - Le marché",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ5/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite6,
    name: "Alphabetisation - 2 - L'hygiène",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ6/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite7,
    name: "Alphabetisation - 2 - La famille",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ7/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite8,
    name: "Alphabetisation - 2 - La météo",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ8/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_2_logo,
    name: "Tanga 2",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-deux/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_3_logo,
    name: "Tanga 3",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-trois/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "kenya",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  // MALI
  {
    imageUrl: app_logo.network,
    name: "Network",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/network",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.alpha_app_video,
    name: "Alpha App Vidéo",
    category: ["alphabetisation", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/alphaappvideo/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lekki,
    name: "Lekki",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lekki/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.voambolana,
    name: "Voambolana",
    category: ["lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/voambolana/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.labyrinthe_oasis,
    name: "Labyrinthe Oasis",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/labyrinthe-oasis",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.inclusion_financiere,
    name: "Inclusion Financière",
    category: ["tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/inclusion/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.activExam,
    name: "ActivExam",
    category: ["lettres", "math"],
    app_repos: "https://privateapps.africatik.com/activexam-primaire/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.raqmi,
    name: "Raqmi",
    category: ["sciences", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/raqmi",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [3, 4, 5, 6, 7, 8],
    orientation: "both",
  },
  {
    imageUrl: app_logo.ecologie,
    name: "Ecologie",
    category: ["tc", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/ecologie/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chenza_ouest,
    name: "Chenza Ouest",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chenza-ouest",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.kaluun,
    name: "Kaluun",
    category: ["lettres", "math", "logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/kaluun/",
    bgColor: "#2CE1FF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.africoloriage,
    name: "Africoloriage",
    category: ["logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/afriColoriage/",
    bgColor: "#00A5DF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.geo,
    name: "Géographie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/geographie_afrique/",
    bgColor: "#DEAA87",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_ouest,
    name: "Tanga Ouest",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-ouest-v1.0.0/",
    bgColor: "#000000",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chimie_ouest,
    name: "Chimie Les mélanges Ouest",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chimie-ouest-v1.1.6",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kinzazi_ouest,
    name: "Kinzazi Ouest",
    category: ["sciences"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/kinzazi-ouest-v1.3.0",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_primaire_ouest,
    name: "Mutshi Primaire Ouest",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/mutshi-primaire-ouest",
    bgColor: "#EEEEEE",
    cancelColor: "#000000",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_maternelle_ouest,
    name: "Mutshi Maternelle Ouest",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/mutshi-maternelle-ouest",
    bgColor: "#EEEEEE",
    cancelColor: "#000000",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [0],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bamako_puzzle,
    name: "Bamako Puzzle 2",
    category: ["logique", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bamakopuzzle/",
    bgColor: "#B4AFB9",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bongo_ouest,
    name: "Bongo Ouest",
    category: ["logique", "math", "lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bongo_ouest",
    bgColor: "#CF6C35",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.koopar,
    name: "Koopar",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/koopar/",
    bgColor: "#78BB6E",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutuka,
    name: "Mutuka",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutuka-apps",
    bgColor: "#52D1E7",
    cancelColor: "#CC0022",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bimuma,
    name: "Bimuma",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bimuma",
    bgColor: "#BBBEB5",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.anatomie,
    name: "Anatomie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/anatomie_v1.3.0",
    bgColor: "#000000",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.jdl,
    name: "Jeu de Lettres",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/jdl",
    bgColor: "#90EE90",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite1,
    name: "Alphabetisation - 1 - L'alphabet",
    category: ["alphabetisation", "tc", "lettres"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ1/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite2,
    name: "Alphabetisation - 2 - Les sons",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ2/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite3,
    name: "Alphabetisation - 3 - Les nombres",
    category: ["alphabetisation", "math", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ3/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite4,
    name: "Alphabetisation - 4 - Les salutations",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ4/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite5,
    name: "Alphabetisation - 5 - Le marché",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ5/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite6,
    name: "Alphabetisation - 2 - L'hygiène",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ6/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite7,
    name: "Alphabetisation - 2 - La famille",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ7/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite8,
    name: "Alphabetisation - 2 - La météo",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ8/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_2_logo,
    name: "Tanga 2",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-deux/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_3_logo,
    name: "Tanga 3",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-trois/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mali",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  // RCA
  {
    imageUrl: app_logo.network,
    name: "Network",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/network",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lekki,
    name: "Lekki",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lekki/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.voambolana,
    name: "Voambolana",
    category: ["lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/voambolana/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.labyrinthe_oasis,
    name: "Labyrinthe Oasis",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/labyrinthe-oasis",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.activExam,
    name: "ActivExam",
    category: ["lettres", "math"],
    app_repos: "https://privateapps.africatik.com/activexam-primaire/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.inclusion_financiere,
    name: "Inclusion Financière",
    category: ["tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/inclusion/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kaluun,
    name: "Kaluun",
    category: ["lettres", "math", "logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/kaluun/",
    bgColor: "#2CE1FF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.africoloriage,
    name: "Africoloriage",
    category: ["logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/afriColoriage/",
    bgColor: "#00A5DF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.geo,
    name: "Géographie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/geographie_afrique/",
    bgColor: "#DEAA87",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.koopar,
    name: "Koopar",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/koopar/",
    bgColor: "#78BB6E",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutuka,
    name: "Mutuka",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutuka-apps",
    bgColor: "#52D1E7",
    cancelColor: "#CC0022",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chenza,
    name: "Chenza",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chenza-fruits-apps",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.kinzazi,
    name: "Kinzazi",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/kinzazi-apps",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.mutshi_primaire,
    name: "Mutshi Primaire",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutshiPrimaire",
    bgColor: "#EEEEEE",
    level: [1, 2, 3, 4, 5, 6],
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_maternelle,
    name: "Mutshi M",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutshiMaternelle",
    bgColor: "#EEEEEE",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [0],
    orientation: "both",
  },
  {
    imageUrl: app_logo.chimie,
    name: "Chimie Les mélanges",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chimie",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.kinPuzzle,
    name: "Kin Puzzle 2",
    category: ["logique", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/kin-puzzle-rdc",
    bgColor: "#B4AFB9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bimuma,
    name: "Bimuma",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bimuma",
    bgColor: "#BBBEB5",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.anatomie,
    name: "Anatomie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/anatomie_v1.3.0",
    bgColor: "#000000",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.jdl,
    name: "Jeu de Lettres",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/jdl",
    bgColor: "#90EE90",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga,
    name: "Tanga",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-apps/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bongo,
    name: "Bongo",
    category: ["logique", "math", "lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bongo",
    bgColor: "#CF6C35",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.alpha_app_video,
    name: "Alpha App Vidéo",
    category: ["alphabetisation", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/alphaappvideo/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite1,
    name: "Alphabetisation - 1 - L'alphabet",
    category: ["alphabetisation", "tc", "lettres"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ1/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite2,
    name: "Alphabetisation - 2 - Les sons",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ2/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite3,
    name: "Alphabetisation - 3 - Les nombres",
    category: ["alphabetisation", "math", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ3/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite4,
    name: "Alphabetisation - 4 - Les salutations",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ4/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite5,
    name: "Alphabetisation - 5 - Le marché",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ5/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite6,
    name: "Alphabetisation - 2 - L'hygiène",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ6/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite7,
    name: "Alphabetisation - 2 - La famille",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ7/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite8,
    name: "Alphabetisation - 2 - La météo",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ8/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_2_logo,
    name: "Tanga 2",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-deux/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_3_logo,
    name: "Tanga 3",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-trois/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [0, 1, 2, 3],
    orientation: "land",
  },

  // SOMALIE
  {
    imageUrl: app_logo.labyrinthe_oasis,
    name: "Labyrinthe Oasis",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/labyrinthe-oasis",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lekki,
    name: "Lekki",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lekki/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.alpha_app_video,
    name: "Alpha App Vidéo",
    category: ["alphabetisation", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/alphaappvideo/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.voambolana,
    name: "Voambolana",
    category: ["lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/voambolana/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.network,
    name: "Network",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/network",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.raqmi,
    name: "Raqmi",
    category: ["sciences", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/raqmi",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [3, 4, 5, 6, 7, 8],
    orientation: "both",
  },
  {
    imageUrl: app_logo.activExam,
    name: "ActivExam",
    category: ["lettres", "math"],
    app_repos: "https://privateapps.africatik.com/activexam-primaire/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.inclusion_financiere,
    name: "Inclusion Financière",
    category: ["tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/inclusion/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kaluun,
    name: "Kaluun",
    category: ["lettres", "math", "logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/kaluun/",
    bgColor: "#2CE1FF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.africoloriage,
    name: "Africoloriage",
    category: ["logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/afriColoriage/",
    bgColor: "#00A5DF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.ecologie,
    name: "Ecologie",
    category: ["tc", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/ecologie/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_est,
    name: "Tanga Est",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-est-v1.0.0/",
    bgColor: "#000000",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.geo,
    name: "Géographie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/geographie_afrique/",
    bgColor: "#DEAA87",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chimie_est,
    name: "Chimie Les mélanges Est",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chimie-est-v1.1.6",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kinzazi_est,
    name: "Kinzazi Est",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/kinzazi-est-v1.3.0",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_primaire_est,
    name: "Mutshi Primaire Est",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutshi-primaire-est",
    bgColor: "#EEEEEE",
    cancelColor: "#000000",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_maternelle_est,
    name: "Mutshi Maternelle Est",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/mutshi-maternelle-est",
    bgColor: "#EEEEEE",
    cancelColor: "#000000",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [0],
    orientation: "land",
  },
  {
    imageUrl: app_logo.djibou_puzzle,
    name: "Djibout Puzzle 2",
    category: ["logique", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/djibouti-puzzle-2",
    bgColor: "#B4AFB9",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bongo_est,
    name: "Bongo Est",
    category: ["logique", "math", "lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bongo_est",
    bgColor: "#CF6C35",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lacagta,
    name: "Lacagta",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lacagta",
    bgColor: "#78BB6E",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutuka,
    name: "Mutuka",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutuka-apps",
    bgColor: "#52D1E7",
    cancelColor: "#CC0022",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bimuma,
    name: "Bimuma",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bimuma",
    bgColor: "#BBBEB5",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.anatomie,
    name: "Anatomie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/anatomie_v1.3.0",
    bgColor: "#000000",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.jdl,
    name: "Jeu de Lettres",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/jdl",
    bgColor: "#90EE90",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite1,
    name: "Alphabetisation - 1 - L'alphabet",
    category: ["alphabetisation", "tc", "lettres"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ1/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite2,
    name: "Alphabetisation - 2 - Les sons",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ2/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite3,
    name: "Alphabetisation - 3 - Les nombres",
    category: ["alphabetisation", "math", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ3/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite4,
    name: "Alphabetisation - 4 - Les salutations",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ4/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite5,
    name: "Alphabetisation - 5 - Le marché",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ5/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite6,
    name: "Alphabetisation - 2 - L'hygiène",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ6/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite7,
    name: "Alphabetisation - 2 - La famille",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ7/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite8,
    name: "Alphabetisation - 2 - La météo",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ8/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_2_logo,
    name: "Tanga 2",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-deux/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_3_logo,
    name: "Tanga 3",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-trois/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "somalie",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  // TOGO
  {
    imageUrl: app_logo.labyrinthe_oasis,
    name: "Labyrinthe Oasis",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/labyrinthe-oasis",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.alpha_app_video,
    name: "Alpha App Vidéo",
    category: ["alphabetisation", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/alphaappvideo/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lekki,
    name: "Lekki",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lekki/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.voambolana,
    name: "Voambolana",
    category: ["lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/voambolana/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.network,
    name: "Network",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/network",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.raqmi,
    name: "Raqmi",
    category: ["sciences", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/raqmi",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [3, 4, 5, 6, 7, 8],
    orientation: "both",
  },
  {
    imageUrl: app_logo.inclusion_financiere,
    name: "Inclusion Financière",
    category: ["tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/inclusion/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.activExam,
    name: "ActivExam",
    category: ["lettres", "math"],
    app_repos: "https://privateapps.africatik.com/activexam-primaire/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kaluun,
    name: "Kaluun",
    category: ["lettres", "math", "logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/kaluun/",
    bgColor: "#2CE1FF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.ecologie,
    name: "Ecologie",
    category: ["tc", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/ecologie/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.africoloriage,
    name: "Africoloriage",
    category: ["logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/afriColoriage/",
    bgColor: "#00A5DF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_ouest,
    name: "Tanga Ouest",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-ouest-v1.0.0/",
    bgColor: "#000000",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.geo,
    name: "Géographie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/geographie_afrique/",
    bgColor: "#DEAA87",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kinzazi_ouest,
    name: "Kinzazi Ouest",
    category: ["sciences"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/kinzazi-ouest-v1.3.0",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_primaire_ouest,
    name: "Mutshi Primaire Ouest",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/mutshi-primaire-ouest",
    bgColor: "#EEEEEE",
    cancelColor: "#000000",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_maternelle_ouest,
    name: "Mutshi Maternelle Ouest",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/mutshi-maternelle-ouest",
    bgColor: "#EEEEEE",
    cancelColor: "#000000",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [0],
    orientation: "land",
  },
  {
    imageUrl: app_logo.dakar_puzzle,
    name: "Dakar Puzzle 2",
    category: ["logique", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/dakar-puzzle-2",
    bgColor: "#B4AFB9",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bongo_ouest,
    name: "Bongo Ouest",
    category: ["logique", "math", "lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bongo_ouest",
    bgColor: "#CF6C35",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.koopar,
    name: "Koopar",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/koopar/",
    bgColor: "#78BB6E",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutuka,
    name: "Mutuka",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutuka-apps",
    bgColor: "#52D1E7",
    cancelColor: "#CC0022",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bimuma,
    name: "Bimuma",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bimuma",
    bgColor: "#BBBEB5",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.anatomie,
    name: "Anatomie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/anatomie_v1.3.0",
    bgColor: "#000000",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.jdl,
    name: "Jeu de Lettres",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/jdl",
    bgColor: "#90EE90",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite1,
    name: "Alphabetisation - 1 - L'alphabet",
    category: ["alphabetisation", "tc", "lettres"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ1/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite2,
    name: "Alphabetisation - 2 - Les sons",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ2/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite3,
    name: "Alphabetisation - 3 - Les nombres",
    category: ["alphabetisation", "math", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ3/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite4,
    name: "Alphabetisation - 4 - Les salutations",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ4/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite5,
    name: "Alphabetisation - 5 - Le marché",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ5/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite6,
    name: "Alphabetisation - 2 - L'hygiène",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ6/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite7,
    name: "Alphabetisation - 2 - La famille",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ7/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite8,
    name: "Alphabetisation - 2 - La météo",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ8/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_2_logo,
    name: "Tanga 2",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-deux/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_3_logo,
    name: "Tanga 3",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-trois/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "togo",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  // HAITI
  {
    imageUrl: app_logo.network,
    name: "Network",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/network",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lekki,
    name: "Lekki",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lekki/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.alpha_app_video,
    name: "Alpha App Vidéo",
    category: ["alphabetisation", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/alphaappvideo/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.voambolana,
    name: "Voambolana",
    category: ["lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/voambolana/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.inclusion_financiere,
    name: "Inclusion Financière",
    category: ["tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/inclusion/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.labyrinthe_oasis,
    name: "Labyrinthe Oasis",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/labyrinthe-oasis",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.raqmi,
    name: "Raqmi",
    category: ["sciences", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/raqmi",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [3, 4, 5, 6, 7, 8],
    orientation: "both",
  },
  {
    imageUrl: app_logo.ecologie,
    name: "Ecologie",
    category: ["tc", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/ecologie/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.activExam,
    name: "ActivExam",
    category: ["lettres", "math"],
    app_repos: "https://privateapps.africatik.com/activexam-primaire/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kaluun,
    name: "Kaluun",
    category: ["lettres", "math", "logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/kaluun/",
    bgColor: "#2CE1FF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.africoloriage,
    name: "Africoloriage",
    category: ["logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/afriColoriage/",
    bgColor: "#00A5DF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.haiti_puzzle,
    name: "Haiti puzzle 2",
    category: ["logique", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/haiti-puzzle-2",
    bgColor: "#B4AFB9",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutuka,
    name: "Mutuka",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutuka-apps",
    bgColor: "#52D1E7",
    cancelColor: "#CC0022",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chenza,
    name: "Chenza",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chenza-fruits-apps",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },

  {
    imageUrl: app_logo.kinzazi,
    name: "Kinzazi",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/kinzazi-apps",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.mutshi_maternelle,
    name: "Mutshi M",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutshiMaternelle",
    bgColor: "#EEEEEE",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [0],
    orientation: "both",
  },
  {
    imageUrl: app_logo.mutshi_primaire,
    name: "Mutshi P",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutshiPrimaire",
    bgColor: "#EEEEEE",
    level: [1, 2, 3, 4, 5, 6],
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    orientation: "land",
  },
  {
    imageUrl: app_logo.bongo,
    name: "Bongo",
    category: ["logique", "lettres", "math", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bongo",
    bgColor: "#CF6C35",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chimie,
    name: "Chimie Les mélanges",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chimie",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.geo,
    name: "Géographie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/geographie_afrique/",
    bgColor: "#DEAA87",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.jdl,
    name: "Jeu de Lettres",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/jdl",
    bgColor: "#90EE90",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bimuma,
    name: "Bimuma",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bimuma",
    bgColor: "#BBBEB5",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.anatomie,
    name: "Anatomie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/anatomie_v1.3.0",
    bgColor: "#000000",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite1,
    name: "Alphabetisation - 1 - L'alphabet",
    category: ["alphabetisation", "tc", "lettres"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ1/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite2,
    name: "Alphabetisation - 2 - Les sons",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ2/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite3,
    name: "Alphabetisation - 3 - Les nombres",
    category: ["alphabetisation", "math", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ3/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite4,
    name: "Alphabetisation - 4 - Les salutations",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ4/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite5,
    name: "Alphabetisation - 5 - Le marché",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ5/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite6,
    name: "Alphabetisation - 2 - L'hygiène",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ6/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite7,
    name: "Alphabetisation - 2 - La famille",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ7/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite8,
    name: "Alphabetisation - 2 - La météo",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ8/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_2_logo,
    name: "Tanga 2",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-deux/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_3_logo,
    name: "Tanga 3",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-trois/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "haiti",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  // MAURITANIE
  {
    imageUrl: app_logo.network,
    name: "Network",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/network",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lekki,
    name: "Lekki",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lekki/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.alpha_app_video,
    name: "Alpha App Vidéo",
    category: ["alphabetisation", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/alphaappvideo/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.voambolana,
    name: "Voambolana",
    category: ["lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/voambolana/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.labyrinthe_oasis,
    name: "Labyrinthe Oasis",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/labyrinthe-oasis",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.raqmi,
    name: "Raqmi",
    category: ["sciences", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/raqmi",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [3, 4, 5, 6, 7, 8],
    orientation: "both",
  },
  {
    imageUrl: app_logo.kinzazi_nord,
    name: "Kinzazi Nord",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/kinzazi-nord",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.kinzazi_ouest,
    name: "Kinzazi Ouest",
    category: ["sciences"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/kinzazi-ouest-v1.3.0",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.ecologie,
    name: "Ecologie",
    category: ["tc", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/ecologie/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.inclusion_financiere,
    name: "Inclusion Financière",
    category: ["tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/inclusion/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.activExam,
    name: "ActivExam",
    category: ["lettres", "math"],
    app_repos: "https://privateapps.africatik.com/activexam-primaire/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kaluun,
    name: "Kaluun",
    category: ["lettres", "math", "logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/kaluun/",
    bgColor: "#2CE1FF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.africoloriage,
    name: "Africoloriage",
    category: ["logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/afriColoriage/",
    bgColor: "#00A5DF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.makuta_nord,
    name: "Makuta nord",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/makuta-nord/",
    bgColor: "#78BB6E",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.raqmi,
    name: "Raqmi",
    category: ["sciences", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/raqmi",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "rca",
    level: [3, 4, 5, 6, 7, 8],
    orientation: "both",
  },
  {
    imageUrl: app_logo.chimie_nord,
    name: "Chimie Les mélanges nord",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chimie-nord",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bongo_nord,
    name: "Bongo nord",
    category: ["logique", "lettres", "math", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bongo-nord",
    bgColor: "#CF6C35",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chenza_nord,
    name: "Chenza Nord",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chenza-nord",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.mutuka,
    name: "Mutuka",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutuka-apps",
    bgColor: "#52D1E7",
    cancelColor: "#CC0022",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bimuma,
    name: "Bimuma",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bimuma",
    bgColor: "#BBBEB5",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [0, 1, 2, 3, 4, 5, 6, 8],
    orientation: "land",
  },

  {
    imageUrl: app_logo.mutshi_maternelle_nord,
    name: "Mutshi maternelle nord",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/mutshi-maternelle-nord/",
    bgColor: "#EEEEEE",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [0],
    orientation: "land",
  },

  {
    imageUrl: app_logo.mutshi_primaire_nord,
    name: "Mutshi primaire nord",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/mutshi-primaire-nord/",
    bgColor: "#EEEEEE",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mauritanie_puzzle,
    name: "Mauritanie puzzle 2",
    category: ["logique", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mauritanie-puzzle-2",
    bgColor: "#B4AFB9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },

  {
    imageUrl: app_logo.geo,
    name: "Géographie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/geographie_afrique/",
    bgColor: "#DEAA87",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.anatomie,
    name: "Anatomie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/anatomie_v1.3.0",
    bgColor: "#000000",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.jdl,
    name: "Jeu de Lettres",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/jdl",
    bgColor: "#90EE90",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite1,
    name: "Alphabetisation - 1 - L'alphabet",
    category: ["alphabetisation", "tc", "lettres"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ1/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite2,
    name: "Alphabetisation - 2 - Les sons",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ2/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite3,
    name: "Alphabetisation - 3 - Les nombres",
    category: ["alphabetisation", "math", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ3/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite4,
    name: "Alphabetisation - 4 - Les salutations",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ4/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite5,
    name: "Alphabetisation - 5 - Le marché",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ5/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite6,
    name: "Alphabetisation - 2 - L'hygiène",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ6/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite7,
    name: "Alphabetisation - 2 - La famille",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ7/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite8,
    name: "Alphabetisation - 2 - La météo",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ8/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_2_logo,
    name: "Tanga 2",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-deux/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_3_logo,
    name: "Tanga 3",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-trois/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "mauritanie",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  // TUNISIE
  {
    imageUrl: app_logo.labyrinthe_oasis,
    name: "Labyrinthe Oasis",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/labyrinthe-oasis",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.alpha_app_video,
    name: "Alpha App Vidéo",
    category: ["alphabetisation", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/alphaappvideo/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lekki,
    name: "Lekki",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lekki/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.voambolana,
    name: "Voambolana",
    category: ["lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/voambolana/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_nord_logo,
    name: "Tanga Nord",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-nord/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chenza_nord,
    name: "Chenza Nord",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chenza-nord",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.raqmi,
    name: "Raqmi",
    category: ["sciences", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/raqmi",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [3, 4, 5, 6, 7, 8],
    orientation: "both",
  },
  {
    imageUrl: app_logo.network,
    name: "Network",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/network",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.inclusion_financiere,
    name: "Inclusion Financière",
    category: ["tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/inclusion/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kinzazi_nord,
    name: "Kinzazi Nord",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/kinzazi-nord",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.ecologie,
    name: "Ecologie",
    category: ["tc", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/ecologie/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.activExam,
    name: "ActivExam",
    category: ["lettres", "math"],
    app_repos: "https://privateapps.africatik.com/activexam-primaire/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kaluun,
    name: "Kaluun",
    category: ["lettres", "math", "logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/kaluun/",
    bgColor: "#2CE1FF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.africoloriage,
    name: "Africoloriage",
    category: ["logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/afriColoriage/",
    bgColor: "#00A5DF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_maternelle_nord,
    name: "Mutshi maternelle nord",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/mutshi-maternelle-nord/",
    bgColor: "#EEEEEE",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [0],
    orientation: "land",
  },

  {
    imageUrl: app_logo.mutshi_primaire_nord,
    name: "Mutshi primaire nord",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/mutshi-primaire-nord/",
    bgColor: "#EEEEEE",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.makuta_tunisie,
    name: "Makuta tunisie",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/makuta-tunisie/",
    bgColor: "#78BB6E",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chimie_nord,
    name: "Chimie Les mélanges nord",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chimie-nord",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bongo_nord,
    name: "Bongo nord",
    category: ["logique", "lettres", "math", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bongo-nord",
    bgColor: "#CF6C35",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutuka,
    name: "Mutuka",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutuka-apps",
    bgColor: "#52D1E7",
    cancelColor: "#CC0022",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bimuma,
    name: "Bimuma",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bimuma",
    bgColor: "#BBBEB5",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.geo,
    name: "Géographie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/geographie_afrique/",
    bgColor: "#DEAA87",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.anatomie,
    name: "Anatomie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/anatomie_v1.3.0",
    bgColor: "#000000",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.jdl,
    name: "Jeu de Lettres",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/jdl",
    bgColor: "#90EE90",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite1,
    name: "Alphabetisation - 1 - L'alphabet",
    category: ["alphabetisation", "tc", "lettres"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ1/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite2,
    name: "Alphabetisation - 2 - Les sons",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ2/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite3,
    name: "Alphabetisation - 3 - Les nombres",
    category: ["alphabetisation", "math", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ3/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite4,
    name: "Alphabetisation - 4 - Les salutations",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ4/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite5,
    name: "Alphabetisation - 5 - Le marché",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ5/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite6,
    name: "Alphabetisation - 2 - L'hygiène",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ6/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite7,
    name: "Alphabetisation - 2 - La famille",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ7/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite8,
    name: "Alphabetisation - 2 - La météo",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ8/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tunis_puzzle,
    name: "Tunis Puzzle 2",
    category: ["logique", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tunispuzzle/",
    bgColor: "#B4AFB9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_2_logo,
    name: "Tanga 2",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-deux/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_3_logo,
    name: "Tanga 3",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-trois/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "tunisie",
    level: [0, 1, 2, 3],
    orientation: "land",
  },

  //Supplément

  //Algérie
  {
    imageUrl: app_logo.labyrinthe_oasis,
    name: "Labyrinthe Oasis",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/labyrinthe-oasis",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.alpha_app_video,
    name: "Alpha App Vidéo",
    category: ["alphabetisation", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/alphaappvideo/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite1,
    name: "Alphabetisation - 1 - L'alphabet",
    category: ["alphabetisation", "tc", "lettres"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ1/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite2,
    name: "Alphabetisation - 2 - Les sons",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ2/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite3,
    name: "Alphabetisation - 3 - Les nombres",
    category: ["alphabetisation", "math", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ3/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite4,
    name: "Alphabetisation - 4 - Les salutations",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ4/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite5,
    name: "Alphabetisation - 5 - Le marché",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ5/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite6,
    name: "Alphabetisation - 2 - L'hygiène",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ6/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite7,
    name: "Alphabetisation - 2 - La famille",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ7/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite8,
    name: "Alphabetisation - 2 - La météo",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ8/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lekki,
    name: "Lekki",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lekki/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.voambolana,
    name: "Voambolana",
    category: ["lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/voambolana/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.network,
    name: "Network",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/network",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.raqmi,
    name: "Raqmi",
    category: ["sciences", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/raqmi",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [3, 4, 5, 6, 7, 8],
    orientation: "both",
  },
  {
    imageUrl: app_logo.inclusion_financiere,
    name: "Inclusion Financière",
    category: ["tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/inclusion/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chenza_nord,
    name: "Chenza Nord",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chenza-nord",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.kinzazi_nord,
    name: "Kinzazi Nord",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/kinzazi-nord",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.activExam,
    name: "ActivExam",
    category: ["lettres", "math"],
    app_repos: "https://privateapps.africatik.com/activexam-primaire/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.ecologie,
    name: "Ecologie",
    category: ["tc", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/ecologie/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kaluun,
    name: "Kaluun",
    category: ["lettres", "math", "logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/kaluun/",
    bgColor: "#2CE1FF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.africoloriage,
    name: "Africoloriage",
    category: ["logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/afriColoriage/",
    bgColor: "#00A5DF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.anatomie,
    name: "Anatomie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/anatomie_v1.3.0",
    bgColor: "#000000",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bimuma,
    name: "Bimuma",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bimuma",
    bgColor: "#BBBEB5",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.geo,
    name: "Géographie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/geographie_afrique/",
    bgColor: "#DEAA87",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_primaire_nord,
    name: "Mutshi primaire nord",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/mutshi-primaire-nord/",
    bgColor: "#EEEEEE",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bongo_nord,
    name: "Bongo nord",
    category: ["logique", "lettres", "math", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bongo-nord",
    bgColor: "#CF6C35",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chimie_nord,
    name: "Chimie Les mélanges nord",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chimie-nord",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutuka,
    name: "Mutuka",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutuka-apps",
    bgColor: "#52D1E7",
    cancelColor: "#CC0022",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_2_logo,
    name: "Tanga 2",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-deux/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_3_logo,
    name: "Tanga 3",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-trois/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "algerie",
    level: [0, 1, 2, 3],
    orientation: "land",
  },

  //Maroc
  {
    imageUrl: app_logo.labyrinthe_oasis,
    name: "Labyrinthe Oasis",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/labyrinthe-oasis",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.alpha_app_video,
    name: "Alpha App Vidéo",
    category: ["alphabetisation", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/alphaappvideo/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lekki,
    name: "Lekki",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lekki/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite1,
    name: "Alphabetisation - 1 - L'alphabet",
    category: ["alphabetisation", "tc", "lettres"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ1/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite2,
    name: "Alphabetisation - 2 - Les sons",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ2/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite3,
    name: "Alphabetisation - 3 - Les nombres",
    category: ["alphabetisation", "math", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ3/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite4,
    name: "Alphabetisation - 4 - Les salutations",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ4/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite5,
    name: "Alphabetisation - 5 - Le marché",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ5/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite6,
    name: "Alphabetisation - 2 - L'hygiène",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ6/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite7,
    name: "Alphabetisation - 2 - La famille",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ7/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite8,
    name: "Alphabetisation - 2 - La météo",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ8/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.voambolana,
    name: "Voambolana",
    category: ["lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/voambolana/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.raqmi,
    name: "Raqmi",
    category: ["sciences", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/raqmi",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [3, 4, 5, 6, 7, 8],
    orientation: "both",
  },
  {
    imageUrl: app_logo.tanga_nord_logo,
    name: "Tanga Nord",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-nord/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.makuta_maroc,
    name: "Makuta Maroc",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/makuta-maroc/",
    bgColor: "#78BB6E",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.network,
    name: "Network",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/network",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chenza_nord,
    name: "Chenza Nord",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chenza-nord",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.inclusion_financiere,
    name: "Inclusion Financière",
    category: ["tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/inclusion/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.activExam,
    name: "ActivExam",
    category: ["lettres", "math"],
    app_repos: "https://privateapps.africatik.com/activexam-primaire/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.maroc_puzzle,
    name: "Rabbat Puzzle 2",
    category: ["logique", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/rabatpuzzle/",
    bgColor: "#B4AFB9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.ecologie,
    name: "Ecologie",
    category: ["tc", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/ecologie/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kaluun,
    name: "Kaluun",
    category: ["lettres", "math", "logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/kaluun/",
    bgColor: "#2CE1FF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.africoloriage,
    name: "Africoloriage",
    category: ["logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/afriColoriage/",
    bgColor: "#00A5DF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.anatomie,
    name: "Anatomie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/anatomie_v1.3.0",
    bgColor: "#000000",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bimuma,
    name: "Bimuma",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bimuma",
    bgColor: "#BBBEB5",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.geo,
    name: "Géographie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/geographie_afrique/",
    bgColor: "#DEAA87",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_primaire_nord,
    name: "Mutshi primaire nord",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/mutshi-primaire-nord/",
    bgColor: "#EEEEEE",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bongo_nord,
    name: "Bongo nord",
    category: ["logique", "lettres", "math", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bongo-nord",
    bgColor: "#CF6C35",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chimie_nord,
    name: "Chimie Les mélanges nord",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chimie-nord",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutuka,
    name: "Mutuka",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutuka-apps",
    bgColor: "#52D1E7",
    cancelColor: "#CC0022",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kinzazi_nord,
    name: "Kinzazi Nord",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/kinzazi-nord",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.tanga_2_logo,
    name: "Tanga 2",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-deux/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_3_logo,
    name: "Tanga 3",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-trois/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "maroc",
    level: [0, 1, 2, 3],
    orientation: "land",
  },

  //Egypte
  {
    imageUrl: app_logo.labyrinthe_oasis,
    name: "Labyrinthe Oasis",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/labyrinthe-oasis",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.alpha_app_video,
    name: "Alpha App Vidéo",
    category: ["alphabetisation", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/alphaappvideo/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lekki,
    name: "Lekki",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lekki/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite1,
    name: "Alphabetisation - 1 - L'alphabet",
    category: ["alphabetisation", "tc", "lettres"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ1/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite2,
    name: "Alphabetisation - 2 - Les sons",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ2/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite3,
    name: "Alphabetisation - 3 - Les nombres",
    category: ["alphabetisation", "math", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ3/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite4,
    name: "Alphabetisation - 4 - Les salutations",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ4/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite5,
    name: "Alphabetisation - 5 - Le marché",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ5/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite6,
    name: "Alphabetisation - 2 - L'hygiène",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ6/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite7,
    name: "Alphabetisation - 2 - La famille",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ7/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite8,
    name: "Alphabetisation - 2 - La météo",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ8/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.voambolana,
    name: "Voambolana",
    category: ["lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/voambolana/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.network,
    name: "Network",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/network",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_nord_logo,
    name: "Tanga Nord",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-nord/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.caire_puzzle,
    name: "Caire Puzzle 2",
    category: ["logique", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/caire-puzzle/",
    bgColor: "#B4AFB9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.makuta_egypte,
    name: "Makuta Egypte",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/makuta-egypte/",
    bgColor: "#78BB6E",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chenza_nord,
    name: "Chenza Nord",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chenza-nord",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.raqmi,
    name: "Raqmi",
    category: ["sciences", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/raqmi",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [3, 4, 5, 6, 7, 8],
    orientation: "both",
  },
  {
    imageUrl: app_logo.ecologie,
    name: "Ecologie",
    category: ["tc", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/ecologie/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.activExam,
    name: "ActivExam",
    category: ["lettres", "math"],
    app_repos: "https://privateapps.africatik.com/activexam-primaire/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.inclusion_financiere,
    name: "Inclusion Financière",
    category: ["tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/inclusion/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kaluun,
    name: "Kaluun",
    category: ["lettres", "math", "logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/kaluun/",
    bgColor: "#2CE1FF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.africoloriage,
    name: "Africoloriage",
    category: ["logique", "sciences"],
    app_repos: "https://privateapps.africatik.com/afriColoriage/",
    bgColor: "#00A5DF",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [0, 1],
    orientation: "land",
  },
  {
    imageUrl: app_logo.anatomie,
    name: "Anatomie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/anatomie_v1.3.0",
    bgColor: "#000000",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bimuma,
    name: "Bimuma",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bimuma",
    bgColor: "#BBBEB5",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.geo,
    name: "Géographie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/geographie_afrique/",
    bgColor: "#DEAA87",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_primaire_nord,
    name: "Mutshi primaire nord",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/mutshi-primaire-nord/",
    bgColor: "#EEEEEE",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bongo_nord,
    name: "Bongo nord",
    category: ["logique", "lettres", "math", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bongo-nord",
    bgColor: "#CF6C35",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chimie_nord,
    name: "Chimie Les mélanges nord",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chimie-nord",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutuka,
    name: "Mutuka",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutuka-apps",
    bgColor: "#52D1E7",
    cancelColor: "#CC0022",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kinzazi_nord,
    name: "Kinzazi Nord",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/kinzazi-nord",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.tanga_2_logo,
    name: "Tanga 2",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-deux/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_3_logo,
    name: "Tanga 3",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-trois/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "egypte",
    level: [0, 1, 2, 3],
    orientation: "land",
  },

  //Botswana
  {
    imageUrl: app_logo.labyrinthe_oasis,
    name: "Labyrinthe Oasis",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/labyrinthe-oasis",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lekki,
    name: "Lekki",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lekki/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite1,
    name: "Alphabetisation - 1 - L'alphabet",
    category: ["alphabetisation", "tc", "lettres"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ1/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite2,
    name: "Alphabetisation - 2 - Les sons",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ2/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite3,
    name: "Alphabetisation - 3 - Les nombres",
    category: ["alphabetisation", "math", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ3/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite4,
    name: "Alphabetisation - 4 - Les salutations",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ4/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite5,
    name: "Alphabetisation - 5 - Le marché",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ5/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite6,
    name: "Alphabetisation - 2 - L'hygiène",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ6/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite7,
    name: "Alphabetisation - 2 - La famille",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ7/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite8,
    name: "Alphabetisation - 2 - La météo",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ8/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.voambolana,
    name: "Voambolana",
    category: ["lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/voambolana/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.makuta_botswana,
    name: "Makuta Botswana",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/makuta-botswana/",
    bgColor: "#78BB6E",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_sud_logo,
    name: "Tanga Sud",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-sud-v1.0.0/",
    bgColor: "#000000",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chenza_sud,
    name: "Chenza Sud",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chenza-sud",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.botswana_puzzle,
    name: "Gabo Puzzle 2",
    category: ["logique", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/botswana-puzzle/",
    bgColor: "#B4AFB9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.network,
    name: "Network",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/network",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.raqmi,
    name: "Raqmi",
    category: ["sciences", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/raqmi",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [3, 4, 5, 6, 7, 8],
    orientation: "both",
  },
  {
    imageUrl: app_logo.bimuma,
    name: "Bimuma",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bimuma",
    bgColor: "#BBBEB5",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.inclusion_financiere,
    name: "Inclusion Financière",
    category: ["tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/inclusion/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.ecologie,
    name: "Ecologie",
    category: ["tc", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/ecologie/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.geo,
    name: "Géographie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/geographie_afrique/",
    bgColor: "#DEAA87",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_primaire_sud,
    name: "Mutshi Primaire Sud",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutshi-primaire-sud",
    bgColor: "#EEEEEE",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bongo_sud,
    name: "Bongo Sud",
    category: ["logique", "lettres", "math", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bongo_sud",
    bgColor: "#CF6C35",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chimie_sud,
    name: "Chimie Les mélanges Sud",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chimie-sud-v1.1.6",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kinzazi_sud,
    name: "Kinzazi Sud",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/kinzazi-sud-v1.3.0",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutuka,
    name: "Mutuka",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutuka-apps",
    bgColor: "#52D1E7",
    cancelColor: "#CC0022",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_2_logo,
    name: "Tanga 2",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-deux/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_3_logo,
    name: "Tanga 3",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-trois/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "botswana",
    level: [0, 1, 2, 3],
    orientation: "land",
  },

  //Libéria
  {
    imageUrl: app_logo.labyrinthe_oasis,
    name: "Labyrinthe Oasis",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/labyrinthe-oasis",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lekki,
    name: "Lekki",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lekki/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite1,
    name: "Alphabetisation - 1 - L'alphabet",
    category: ["alphabetisation", "tc", "lettres"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ1/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite2,
    name: "Alphabetisation - 2 - Les sons",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ2/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite3,
    name: "Alphabetisation - 3 - Les nombres",
    category: ["alphabetisation", "math", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ3/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite4,
    name: "Alphabetisation - 4 - Les salutations",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ4/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite5,
    name: "Alphabetisation - 5 - Le marché",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ5/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite6,
    name: "Alphabetisation - 2 - L'hygiène",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ6/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite7,
    name: "Alphabetisation - 2 - La famille",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ7/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite8,
    name: "Alphabetisation - 2 - La météo",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ8/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.voambolana,
    name: "Voambolana",
    category: ["lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/voambolana/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.liberia_puzzle,
    name: "Monro Puzzle 2",
    category: ["logique", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/liberia-puzzle/",
    bgColor: "#B4AFB9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_ouest,
    name: "Tanga Ouest",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-ouest-v1.0.0/",
    bgColor: "#000000",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chenza_ouest,
    name: "Chenza Ouest",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chenza-ouest",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.raqmi,
    name: "Raqmi",
    category: ["sciences", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/raqmi",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [3, 4, 5, 6, 7, 8],
    orientation: "both",
  },
  {
    imageUrl: app_logo.makuta_liberia,
    name: "Makuta Liberia",
    category: ["math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/makuta-liberia/",
    bgColor: "#78BB6E",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.network,
    name: "Network",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/network",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.inclusion_financiere,
    name: "Inclusion Financière",
    category: ["tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/inclusion/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bimuma,
    name: "Bimuma",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bimuma",
    bgColor: "#BBBEB5",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.geo,
    name: "Géographie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/geographie_afrique/",
    bgColor: "#DEAA87",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.ecologie,
    name: "Ecologie",
    category: ["tc", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/ecologie/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_primaire_ouest,
    name: "Mutshi Primaire Ouest",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/mutshi-primaire-ouest",
    bgColor: "#EEEEEE",
    cancelColor: "#000000",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bongo_ouest,
    name: "Bongo Ouest",
    category: ["logique", "lettres", "math", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bongo_ouest",
    bgColor: "#CF6C35",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chimie_ouest,
    name: "Chimie Les mélanges Ouest",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chimie-ouest-v1.1.6",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kinzazi_ouest,
    name: "Kinzazi Ouest",
    category: ["sciences"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/kinzazi-ouest-v1.3.0",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutuka,
    name: "Mutuka",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutuka-apps",
    bgColor: "#52D1E7",
    cancelColor: "#CC0022",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_2_logo,
    name: "Tanga 2",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-deux/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_3_logo,
    name: "Tanga 3",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-trois/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "liberia",
    level: [0, 1, 2, 3],
    orientation: "land",
  },

  //Sierra Leonne
  {
    imageUrl: app_logo.labyrinthe_oasis,
    name: "Labyrinthe Oasis",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/labyrinthe-oasis",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.lekki,
    name: "Lekki",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/lekki/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite1,
    name: "Alphabetisation - 1 - L'alphabet",
    category: ["alphabetisation", "tc", "lettres"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ1/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite2,
    name: "Alphabetisation - 2 - Les sons",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ2/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite3,
    name: "Alphabetisation - 3 - Les nombres",
    category: ["alphabetisation", "math", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ3/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite4,
    name: "Alphabetisation - 4 - Les salutations",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ4/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite5,
    name: "Alphabetisation - 5 - Le marché",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ5/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite6,
    name: "Alphabetisation - 2 - L'hygiène",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ6/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite7,
    name: "Alphabetisation - 2 - La famille",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ7/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.logo_activite8,
    name: "Alphabetisation - 2 - La météo",
    category: ["alphabetisation", "tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/alphabetisation/activ8/",
    bgColor: "#000000",
    cancelColor: "#ffffff",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.voambolana,
    name: "Voambolana",
    category: ["lettres", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/voambolana/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_ouest,
    name: "Tanga Ouest",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-ouest-v1.0.0/",
    bgColor: "#000000",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.network,
    name: "Network",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/network",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.sierra_leone_puzzle,
    name: "Free Town Puzzle 2",
    category: ["logique", "sciences"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/sierraleonne-puzzle/",
    bgColor: "#B4AFB9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.makuta_sierra_leone,
    name: "Makuta Sierra Leone",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/makuta-sierra-leone/",
    bgColor: "#78BB6E",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chenza_ouest,
    name: "Chenza Ouest",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chenza-ouest",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "both",
  },
  {
    imageUrl: app_logo.raqmi,
    name: "Raqmi",
    category: ["sciences", "tc"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/raqmi",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [3, 4, 5, 6, 7, 8],
    orientation: "both",
  },
  {
    imageUrl: app_logo.inclusion_financiere,
    name: "Inclusion Financière",
    category: ["tc"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/inclusion/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.ecologie,
    name: "Ecologie",
    category: ["tc", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/ecologie/",
    bgColor: "#8AAEBB",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bimuma,
    name: "Bimuma",
    category: ["logique"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bimuma",
    bgColor: "#BBBEB5",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    orientation: "land",
  },
  {
    imageUrl: app_logo.geo,
    name: "Géographie",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/geographie_afrique/",
    bgColor: "#DEAA87",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutshi_primaire_ouest,
    name: "Mutshi Primaire Ouest",
    category: ["math"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/mutshi-primaire-ouest",
    bgColor: "#EEEEEE",
    cancelColor: "#000000",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.bongo_ouest,
    name: "Bongo Ouest",
    category: ["logique", "lettres", "math", "sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/bongo_ouest",
    bgColor: "#CF6C35",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [0, 1, 2],
    orientation: "land",
  },
  {
    imageUrl: app_logo.chimie_ouest,
    name: "Chimie Les mélanges Ouest",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/chimie-ouest-v1.1.6",
    bgColor: "#477C7C",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.kinzazi_ouest,
    name: "Kinzazi Ouest",
    category: ["sciences"],
    app_repos:
      "https://static.africatik.com/PROD/APPS_WEB/kinzazi-ouest-v1.3.0",
    bgColor: "#EEEEEE",
    cancelColor: "#FFB600",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [0, 1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.mutuka,
    name: "Mutuka",
    category: ["sciences"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/mutuka-apps",
    bgColor: "#52D1E7",
    cancelColor: "#CC0022",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [4, 5, 6],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_2_logo,
    name: "Tanga 2",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-deux/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [0, 1, 2, 3],
    orientation: "land",
  },
  {
    imageUrl: app_logo.tanga_3_logo,
    name: "Tanga 3",
    category: ["lettres"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/tanga-trois/",
    bgColor: "#00C3FF",
    cancelColor: "#FFFDF4",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "sierra_leonne",
    level: [0, 1, 2, 3],
    orientation: "land",
  },

  //Europe
  {
    imageUrl: app_logo.makuta_euro,
    name: "Makuta Euro",
    category: ["alphabetisation", "math"],
    app_repos: "https://static.africatik.com/PROD/APPS_WEB/makuta-euro/",
    bgColor: "#78BB6E",
    cancelColor: "#D3D3C9",
    description:
      "Tanga est un outil d’apprentissage de la lecture en français, en anglais et en langues africaines. Il propose des histoires interactives et liées au contexte africain pour aider les enfants à comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des structures de phrases. Le livre « Le voyage d’Ita » est l’aventure d’une petite fille dans la campagne de RD Congo, c’est une histoire illustrée, écrite et lue.",
    country: "europe",
    level: [1, 2, 3, 4, 5, 6],
    orientation: "land",
  },
];

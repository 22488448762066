import rdc from "./drapeau_img/rdc.png";
import djibouti from "./drapeau_img/djibouti.png";
import mada from "./drapeau_img/madagascar.png";
import senegal from "./drapeau_img/senegal_cam.png";
import cameroun from "./drapeau_img/cameroun.png";
import congo from "./drapeau_img/congo.png";
import cote_divoire from "./drapeau_img/cote_divoire.png";
import ethiopie from "./drapeau_img/ethiopie.png";
import gabon from "./drapeau_img/gabon.png";
import guinee from "./drapeau_img/guinee.png";
import kenya from "./drapeau_img/kenya.png";
import mali from "./drapeau_img/mali.png";
import rca from "./drapeau_img/rca.png";
import somalie from "./drapeau_img/somalie.png";
import togo from "./drapeau_img/togo.png";
import haiti from "./drapeau_img/haiti.png";
import mauritanie from "./drapeau_img/mauritanie.png";
import tunisie from "./drapeau_img/tunisie.png";
//Supplement Emmanuel Bulakitadi
import egypte from "./drapeau_img/Flag_of_Egypt.png";
import europe from "./drapeau_img/Flag_of_Europe.png";
import algerie from "./drapeau_img/Flag_of_Algeria.png";
import botswana from "./drapeau_img/Flag_of_Botswana.png";
import liberia from "./drapeau_img/Flag_of_Liberia.png";
import maroc from "./drapeau_img/Flag_of_Morocco.png";
import sierra_leone from "./drapeau_img/Flag_of_Sierra_Leone.png";

import activExam from "./app_logo/activexam_logo.png";
import africoloriage from "./app_logo/africoloriage_logo.jpg";
import alpha_app_video from "./app_logo/alpha_app_video_logo.png";
import abidjan_puzzle from "./app_logo/abidjan_puzzle_logo.png";
import botswana_puzzle from "./app_logo/botswana_puzzle_logo.png";
import kaluun from "./app_logo/kaluun_logo.jpg";
import anatomie from "./app_logo/anatomie_logo.jpg";
import bamako_puzzle from "./app_logo/bamako_puzzle_logo.jpg";
import bimuma from "./app_logo/bimuma_logo.jpg";
import bongo_est from "./app_logo/bongo_est_logo.jpg";
import bongo from "./app_logo/bongo_logo.jpg";
import bongo_nord from "./app_logo/bongo_nord_logo.jpg";
import bongo_ouest from "./app_logo/bongo_ouest_logo.png";
import bongo_sud from "./app_logo/bongo_sud_logo.jpg";
import chenza from "./app_logo/chenza_logo.jpg";
import chenza_est from "./app_logo/chenza_est_logo.png";
import chenza_ouest from "./app_logo/chenza_ouest_logo.png";
import chenza_nord from "./app_logo/chenza_nord_logo.png";
import chenza_sud from "./app_logo/chenza_sud_logo.png";
import chimie_est from "./app_logo/chimie_est_logo.png";
import chimie from "./app_logo/chimie_logo.jpg";
import chimie_nord from "./app_logo/chimie_nord_logo.jpg";
import chimie_ouest from "./app_logo/chimie_ouest_logo.png";
import chimie_sud from "./app_logo/chimie_sud_logo.png";
import conakry_puzzle from "./app_logo/conakry_puzzle_logo.png";
import dakar_puzzle from "./app_logo/dakar_puzzle_logo.jpg";
import djibou_puzzle from "./app_logo/djibou_puzzle_logo.jpg";
import ecologie from "./app_logo/ecologie_logo.png";
import geo from "./app_logo/geo_logo.jpg";
import inclusion_financiere from "./app_logo/inclusion_financiere_logo.png";
import jdl from "./app_logo/jdl_logo.png";
import kinPuzzle from "./app_logo/kinPuzzle_logo.png";
import kinzazi_est from "./app_logo/kinzazi_est_logo.png";
import kinzazi from "./app_logo/kinzazi_logo.jpg";
import kinzazi_ouest from "./app_logo/kinzazi_ouest_logo.jpg";
import kinzazi_sud from "./app_logo/kinzazi_sud_logo.png";
import kinzazi_nord from "./app_logo/kinzazi_nord_logo.jpg";
import koopar from "./app_logo/koopar_logo.jpg";
import lacagta from "./app_logo/lacagta_logo.png";
import labyrinthe_oasis from "./app_logo/labyrinthe_oasis_logo.png";
import lekki from "./app_logo/lekki_logo.png";
import liberia_puzzle from "./app_logo/liberia_puzzle_logo.png";
import caire_puzzle from "./app_logo/caire_puzzle_logo.png";
import haiti_puzzle from "./app_logo/haiti_puzzle_logo.png";
import mada_puzzle from "./app_logo/mada_puzzle_logo.jpg";
import makuta from "./app_logo/makuta_logo.png";
import makuta_nord from "./app_logo/makuta_nord_logo.jpg";
import makuta_maroc from "./app_logo/makuta_maroc_logo.png";
import makuta_egypte from "./app_logo/makuta_egypte_logo.png";
import makuta_botswana from "./app_logo/makuta_botswana_logo.png";
import makuta_liberia from "./app_logo/makuta_liberia_logo.png";
import makuta_euro from "./app_logo/makuta_euro_logo.png";
import makuta_sierra_leone from "./app_logo/makuta_sierra_leone_logo.png";
import maroc_puzzle from "./app_logo/maroc_puzzle_logo.png";
import makuta_tunisie from "./app_logo/makuta_tunisie_logo.png";
import mauritanie_puzzle from "./app_logo/mauritanie_puzzle_logo.jpg";
import mutshi_maternelle_est from "./app_logo/mutshi_maternelle_est_logo.png";
import mutshi_maternelle from "./app_logo/mutshi_maternelle.jpg";
import mutshi_maternelle_ouest from "./app_logo/mutshi_maternelle_ouest.png";
import mutshi_maternelle_sud from "./app_logo/mutshi_maternelle_sud_logo.png";
import mutshi_primaire_est from "./app_logo/mutshi_primaire_est_logo.png";
import mutshi_primaire from "./app_logo/mutshi_primaire_logo.jpg";
import mutshi_primaire_ouest from "./app_logo/mutshi_primaire_ouest.png";
import mutshi_maternelle_nord from "./app_logo/mutshi_maternelle_nord_logo.jpg";
import mutshi_primaire_nord from "./app_logo/mutshi_primaire_nord_logo.jpg";
import mutshi_primaire_sud from "./app_logo/mutshi_primaire_sud_logo.png";
import mutuka from "./app_logo/mutuka_logo.jpg";
import network from "./app_logo/network_logo.png";
import raqmi from "./app_logo/raqmi_logo.png";
import sierra_leone_puzzle from "./app_logo/sierra_leone_puzzle_logo.png";
import tanga_est from "./app_logo/tanga_est_logo.png";
import tanga from "./app_logo/tanga_logo.png";
import tanga_ouest from "./app_logo/tanga_ouest_logo.jpg";
import tanga_sud_logo from "./app_logo/tanga_sud_logo.png";
import tanga_nord_logo from "./app_logo/Tanga_Nord_logo.png";
import tanga_2_logo from "./app_logo/tanga_2_logo.png";
import tanga_3_logo from "./app_logo/tanga_3_logo.png";
import tunis_puzzle from "./app_logo/tunis_puzzle_logo.jpg";
import vola from "./app_logo/vola_logo.jpg";
import voambolana from "./app_logo/voambolana_logo.png";
import yaounde_puzzle from "./app_logo/yaounde_puzzle_logo.jpg";
import africatik_logo from "./app_logo/africatik_logo.svg";

import logo_activite1 from "./alpha_app/logo_activite1.png";
import logo_activite2 from "./alpha_app/logo_activite2.png";
import logo_activite3 from "./alpha_app/logo_activite3.png";
import logo_activite4 from "./alpha_app/logo_activite4.png";
import logo_activite5 from "./alpha_app/logo_activite5.png";
import logo_activite6 from "./alpha_app/logo_activite6.png";
import logo_activite7 from "./alpha_app/logo_activite7.png";
import logo_activite8 from "./alpha_app/logo_activite8.png";

export const countryFlag = {
  rdc,
  djibouti,
  mada,
  senegal,
  cameroun,
  congo,
  cote_divoire,
  ethiopie,
  gabon,
  guinee,
  kenya,
  mali,
  rca,
  somalie,
  togo,
  haiti,
  mauritanie,
  tunisie,
  //suppelements
  egypte,
  sierra_leone,
  maroc,
  botswana,
  liberia,
  algerie,
  europe,
};

export const app_logo = {
  activExam,
  africoloriage,
  alpha_app_video,
  kaluun,
  anatomie,
  bimuma,
  bongo_est,
  bongo_nord,
  bongo_ouest,
  bongo_sud,
  bongo,
  botswana_puzzle,
  caire_puzzle,
  chenza,
  chenza_est,
  chenza_ouest,
  chenza_nord,
  chenza_sud,
  chimie,
  chimie_est,
  chimie_nord,
  chimie_ouest,
  chimie_sud,
  conakry_puzzle,
  bamako_puzzle,
  dakar_puzzle,
  djibou_puzzle,
  ecologie,
  geo,
  inclusion_financiere,
  kinPuzzle,
  jdl,
  kinzazi,
  kinzazi_est,
  kinzazi_ouest,
  kinzazi_sud,
  kinzazi_nord,
  koopar,
  labyrinthe_oasis,
  lacagta,
  lekki,
  mada_puzzle,
  haiti_puzzle,
  makuta,
  makuta_nord,
  makuta_tunisie,
  makuta_botswana,
  makuta_egypte,
  makuta_liberia,
  makuta_maroc,
  makuta_sierra_leone,
  makuta_euro,
  maroc_puzzle,
  mauritanie_puzzle,
  sierra_leone_puzzle,
  liberia_puzzle,
  mutshi_maternelle,
  mutshi_maternelle_est,
  mutshi_maternelle_ouest,
  mutshi_maternelle_nord,
  mutshi_maternelle_sud,
  mutshi_primaire,
  mutshi_primaire_est,
  mutshi_primaire_ouest,
  mutshi_primaire_sud,
  mutshi_primaire_nord,
  mutuka,
  network,
  raqmi,
  tanga_est,
  tanga,
  tanga_ouest,
  tanga_sud_logo,
  tanga_nord_logo,
  tanga_2_logo,
  tanga_3_logo,
  tunis_puzzle,
  vola,
  voambolana,
  yaounde_puzzle,
  abidjan_puzzle,
  africatik_logo,

  logo_activite1,
  logo_activite2,
  logo_activite3,
  logo_activite4,
  logo_activite5,
  logo_activite6,
  logo_activite7,
  logo_activite8,
};

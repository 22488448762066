import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface adminData {
  current: any;
  list: any[];
  all: any[];
  search:string;
}

const initialState: adminData = {
  current: {},
  all: [],
  list: [],
  search:"",
};

export const adminSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    setGame: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setGames: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    setAllGames: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
    setGameSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
  },
});
export const { setAllGames,setGame,setGames, setGameSearch } = adminSlice.actions;
export default adminSlice.reducer;

import { isEmpty, toUpper, upperCase, upperFirst } from "lodash";
import { setUserProfil, setUserToken } from "../../redux/userReducer";
import userService from "../../services/userService";
import authService from "../../services/authService";
import gameService from "../../services/gameService";
import productService from "../../services/productService";
import levelService from "../../services/levelService";
import { setAllGames, setGames } from "../../redux/gameReducer";
import { setAllProducts, setProduct } from "../../redux/productReducer";
import { setAllLevels, setLevel } from "../../redux/levelReducer";
import countryService from "../../services/countryService";
import { setAllCountries, setCountry } from "../../redux/countryReducer";
import { getDatabase, onDisconnect, ref } from "firebase/database";
import apps from './apps.json';

class UserHelper {

    private key: string = "africatik_login";

    async setCurrentUser(user: any, dispatch?: any) {
        let result = false;
        if(isEmpty(user)){
            return result;
        }
        let item: any = user;
        if (typeof dispatch === "function") {
            await userService
                .get(user?.id)
                .then((response) => {
                    if(!isEmpty(response.data)){
                        item = response.data;
                        result = true;
                    }
                })
                .catch(() => { });
            dispatch(setUserProfil(item));
        }
        await localStorage.setItem(this.key, JSON.stringify(item));
        return result;
    }

    getUserName(user: any) {
        return `${upperFirst(user?.firstName ?? user?.name)} ${upperCase(
            user?.lastName ?? user?.lastname
        )} ${upperCase(user?.postName ?? user?.postname ?? "")}`;
    }

    async setLogout(dispatch?: any) {
        const tokenId = await authService.getTokenId();
        localStorage.removeItem(this.key);
        await authService.logout(dispatch);
        if (typeof dispatch === "function") {
            dispatch(setUserProfil({}));
            dispatch(setUserToken(""))
        }
        /**@ts-ignore */
        if(!isEmpty(tokenId) || tokenId>0){
            const db = getDatabase();
            const dbRef = ref(db, `login/${tokenId}`);
            onDisconnect(dbRef);
        }
    }

    async getUserProfil() {
        return {
            firstName:"AFRICATIK",
            lastName:"",
            email:"africatik@kanieba.com"
        }
        // try {
        //     let item: any = await localStorage.getItem(this.key);
        //     if (!isEmpty(item) && item!==null && item!==undefined) {
        //         const data = JSON.parse(item);
        //         return data;
        //     }
        // } catch (error) {
            
        // }
        return null;
    }

    async initData(dispatch: any) {
        const user = this.getUserProfil();
        this.setCurrentUser(user,dispatch);
        //await this.getCountries(dispatch);
        await this.getGames(dispatch);
        //await this.getLevels(dispatch);
        //await this.getProducts(dispatch);
    }

    async getGames(dispatch: any) {
        const name = "africatik_game";
        const list = await this.getCahe(name);
        if(list?.length>0){
            //
        }
        // await gameService.getAll().then(response => {
        //     const data = response.data;
        //     const items = data.data ?? [];
        //     dispatch(setAllGames(items));
        //     dispatch(setGames(items));
        //     console.log("GAMES",items);
        // }).catch(() => {

        // })
        const items = apps;
        dispatch(setAllGames(items));
        dispatch(setGames(items));
    }

    async getLevels(dispatch: any) {
        await levelService.getAll().then(response => {
            const data = response.data;
            const elements:any[] = [];
            const items = data.data ?? [];
            items.forEach((p:any)=>{
                elements.push({...p,key:p.id,name:toUpper(p.label)});
            });
            elements.unshift({name:"AUCUN FILTRE",key:"",flag:null,id:0});
            dispatch(setAllLevels(elements));
            dispatch(setLevel({name:"AUCUN FILTRE",key:"",flag:null,id:0}));
        }).catch(() => {

        })
    }

    async getProducts(dispatch: any) {
        await productService.getAll().then(response => {
            const data = response.data;
            const items = data.data ?? [];
            const elements: any[] = [];
            items.forEach((p:any)=>{
                elements.push({...p,name:toUpper(p.name)})
            })
            elements.unshift({ id: 0, name: "AUCUN FILTRE" });
            dispatch(setAllProducts(elements));
            dispatch(setProduct({name:"AUCUN FILTRE",key:"",id:0}));
        }).catch(() => {

        })
    }

    async getCountries(dispatch: any) {
        await countryService.getAll().then(response => {
            const data = response.data;
            const items = data.data ?? [];
            const elements: any[] = [];
            items.forEach((p:any) => {
                elements.push({ ...p, flag: p.flag_url, key: p.id, name: toUpper(p.name) });
            });
            elements.unshift({ name: "AUCUN FILTRE", key: "", flag: null, id: 0 });
            dispatch(setAllCountries(elements));
            dispatch(setCountry({name:"AUCUN FILTRE",key:"",id:0}));
        }).catch(() => {

        })
    }

    private cacheElement(v:any,name:string){
        localStorage.setItem(name,JSON.stringify(v));
    }
    private async getCahe(name:string){
        let item: any = await localStorage.getItem(name);
        if (item != null) {
            const data = JSON.parse(item);
            return data;
        }
        return null;
    }

    getTitle(value:string){
        return !isEmpty(value)?toUpper(value[0] ?? ""):"";
    }

}

export default new UserHelper();
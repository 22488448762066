import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface adminData {
  current: any;
  list: any[];
  all: any[];
}

const initialState: adminData = {
  current: {},
  all: [],
  list: [],
};

export const adminSlice = createSlice({
  name: "level",
  initialState,
  reducers: {
    setLevel: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setLevels: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    setAllLevels: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAllLevels,setLevel,setLevels } = adminSlice.actions;
export default adminSlice.reducer;

import { Plugins } from "@capacitor/core";

export function getOrientation() {
  let orientation = "";

  if (window.screen.orientation.type.includes("portrait")) {
    orientation = "portrait";
  } else {
    orientation = "landscape";
  }

  return orientation;
}

export function getLevelCode(levelName) {
  let levelCode = -1;

  switch (levelName) {
    case "Maternelle":
      levelCode = 0;
      break;

    case "1ère primaire - CP":
      levelCode = 1;
      break;

    case "2ème primaire - CE1":
      levelCode = 2;
      break;

    case "3ème primaire - CE2":
      levelCode = 3;
      break;

    case "4ème primaire - CM1":
      levelCode = 4;
      break;

    case "5ème primaire - CM2":
      levelCode = 5;
      break;

    case "6ème primaire - 6ème":
      levelCode = 6;
      break;

    case "Secondaire":
      levelCode = 7;
      break;

    case "Adultes":
      levelCode = 8;
      break;

    default:
      break;
  }

  return levelCode;
}

export function getDisciplinesCode(discipline) {
  let disciplineCode = "";

  switch (discipline) {
    case "Éveil-Sciences":
      disciplineCode = "sciences";
      break;

    case "Mathématiques":
      disciplineCode = "math";
      break;

    case "Logique":
      disciplineCode = "logique";
      break;

    case "Lettres":
      disciplineCode = "lettres";
      break;

    case "Histoire-Géo":
      disciplineCode = "hist-geo";
      break;

    case "Toutes":
      disciplineCode = "toutes";
      break;

    case "Thèmes citoyens":
      disciplineCode = "tc";
      break;

    // Supplement
    case "Alphabétisation":
      disciplineCode = "alphabetisation";
      break;

    default:
      break;
  }

  return disciplineCode;
}

export function getCountryCode(country) {
  let countryCode = "";

  switch (country) {
    case "CAMEROUN":
      countryCode = "cameroun";

      break;

    case "CONGO":
      countryCode = "congo";
      break;

    case "COTE D'IVOIRE":
      countryCode = "cote_divoire";
      break;

    case "GABON":
      countryCode = "gabon";
      break;

    case "ETHIOPIE":
      countryCode = "ethiopie";
      break;

    case "GUINEE":
      countryCode = "guinee";
      break;

    case "KENYA":
      countryCode = "kenya";
      break;

    case "MALI":
      countryCode = "mali";
      break;

    case "RCA":
      countryCode = "rca";
      break;

    case "SOMALIE":
      countryCode = "somalie";
      break;

    case "RD CONGO":
      countryCode = "rdc";

      break;

    case "SENEGAL":
      countryCode = "senegal";

      break;

    case "DJIBOUTI":
      countryCode = "djibouti";

      break;

    case "MADAGASCAR":
      countryCode = "madagascar";

      break;

    case "TOGO":
      countryCode = "togo";

      break;

    case "HAITI":
      countryCode = "haiti";

      break;

    case "MAURITANIE":
      countryCode = "mauritanie";

      break;

    case "TUNISIE":
      countryCode = "tunisie";

      break;

    //Supplément

    case "ALGERIE":
      countryCode = "algerie";

      break;

    case "BOTSWANA":
      countryCode = "botswana";

      break;

    case "EGYPTE":
      countryCode = "egypte";

      break;

    case "SIERRA LEONE":
      countryCode = "sierra_leonne";

      break;

    case "MAROC":
      countryCode = "maroc";

      break;

    case "EUROPE":
      countryCode = "europe";

      break;

    case "LIBERIA":
      countryCode = "liberia";

      break;

    default:
      break;
  }

  return countryCode;
}

export async function getPlatformInfo() {
  const { Device } = Plugins;

  const info = await Device.getInfo();

  info.then((info) => {
    console.log("alors les amis", info);
  });

  return info;
}

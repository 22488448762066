import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Login from "../components/Login/Login";
import HomeContainer from "../components/HomeContainer/HomeContainer";
import Play from "../components/Play/Play";
import AppDetails from "../components/AppDetails/AppDetails";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import authService from "../services/authService";
import userHelper from "../utils/helpers/userHelper";
import { isEmpty } from "lodash";
import { getDatabase, onDisconnect, onValue, ref } from "firebase/database";
import { ModalComponent } from "../card/modal";
import { SplashScreen } from "../card/splashScreen";



export function ConnectLayout() {

    const [logged, setLogged] = React.useState(false);
    const [appOnline, setAppOnline] = React.useState(true);
    const [open, setOpen] = React.useState(true);
    const [openMessage, setOpenMessage] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const userToken = useSelector((state: RootState) => state.user.token);
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const loc = useLocation();
    const games = useSelector((state: RootState) => state.game.all);
    const countries = useSelector((state: RootState) => state.country.all);
    const products = useSelector((state: RootState) => state.product.all);
    const levels = useSelector((state: RootState) => state.level.all);

    async function init() {
        const user = await userHelper.getUserProfil();
        userHelper.setCurrentUser(user, dispatch);
        if (loc.pathname === '/' || loc.pathname === "") {
            if (games.length === 0 || levels.length === 0 || products.length === 0 || countries.length === 0) {
                setOpen(true);
                await userHelper.initData(dispatch);
                setOpen(false);
            }
        }
        setOpen(false);
    }

    React.useEffect(() => {
        init();
        // setTimeout(init, 2000);
        /**@ts-ignore eslint-disable */
        _paq.push(['trackEvent', 'Menu', 'game']);
    }, [userToken])

    return (
        <React.Fragment>
            <Routes>
                <Route
                    path={`/`}
                    element={<HomeContainer />}
                    key={`login_public`}
                />
                <Route
                    path={`/play`}
                    element={<Play />}
                    key={`connect_play`}
                />
                <Route
                    path={`/appdetail`}
                    element={<AppDetails />}
                    key={`connect_detail`}
                />
            </Routes>
            <ModalComponent
                message={message} open={openMessage}
                id="modal" title="Message important"
                setOpen={setOpenMessage}
            />
            <SplashScreen open={open} setOpen={setOpen} />
        </React.Fragment>

    )
}
import React from "react";
import Modal from '@mui/material/Modal';
import { Box, Typography } from "@mui/material";
import { isEmpty } from "lodash";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};



export const ModalComponent: React.FC<{
    message: string; title?: string;id?:string; open: boolean; setOpen: (v: boolean) => void;
}> = ({ message, open, setOpen, title,id }) => {

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {!isEmpty(title) && (
                    <Typography id={`modal-modal-title${id ?? ""}`} variant="h6" component="h2">
                        {title}
                    </Typography>)}
                    <Typography id={`modal-modal-description${id??''}`} sx={{ mt: 2 }}>
                       {message}
                    </Typography>
                </Box>
            </Modal>
        </div>
    )
}
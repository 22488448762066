import React from "react";
import Grid from "@material-ui/core/Grid";

import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

function AppDetails() {
  return (
    <Grid container justify="space-around" style={{ padding: 10 }}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <IconButton href="/" onClick={() => console.log("Cancel Icon clicked")}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={12} /* style={{backgroundColor: 'yellow'}} */
      >
        <Typography
          variant="h4"
          style={{ textAlign: "center", color: "#E97B00" }}
        >
          MAKUTA
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ textAlign: "justify", marginTop: 30 }}
        >
          Tanga est un outil d’apprentissage de la lecture en français, en
          anglais et en langues africaines. Il propose des histoires
          interactives et liées au contexte africain pour aider les enfants à
          comprendre un récit, enrichir leur vocabulaire ou encore mémoriser des
          structures de phrases. Le livre « Le voyage d’Ita » est l’aventure
          d’une petite fille dans la campagne de RD Congo, c’est une histoire
          illustrée, écrite et lue.
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{ marginTop: 30 }}
        >
          JOUER
        </Button>
      </Grid>
    </Grid>
  );
}

export default AppDetails;

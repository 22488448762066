import axios, { AxiosResponse } from "axios";
import authServiceApi from "./authService";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import config from "./config";
import { isEmpty, toNumber } from "lodash";
import { Location } from 'react-router-dom';
import userHelper from "../utils/helpers/userHelper";

const http = axios.create({
  baseURL: process.env.REACT_APP_ENV =='rec'?process.env.REACT_APP_URL_API_REC:process.env.REACT_APP_URL_API_PROD,
  headers: {
    "Content-Type": "application/json",
  },
});
const CancelToken = axios.CancelToken;
const setAuthHeader = async (config: any) => {
  const token = await authServiceApi.getToken();
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  } else {
    config.cancelToken = new CancelToken((cancel) => cancel("No token"));
  }
  return config;
};

const setResponse = async(value: AxiosResponse<any, any>) =>{
  return value;
}

const setError = (value: any) =>{
  const code = value?.response?.status ?? 200;
  const name = "error_token";
  if(code === 401){
    let d = localStorage.getItem(name);
    let count = 0;
    if(!isEmpty(d)){
      count = toNumber(d) ?? 0;
      count++;
    } 
    localStorage.setItem(name,`${count}`);
    if(count>4){
      authServiceApi.logout();
      localStorage.removeItem(name);
      window.document.location.assign("/home/");
    }
  }else{
    localStorage.removeItem(name);
  }
  return value;
}

http.interceptors.request.use(setAuthHeader);
http.interceptors.response.use(setResponse,setError);

// createAuthRefreshInterceptor(http, (failedRequest) =>
//   authServiceApi.relogin().then((result) => {
//     if (result === true) {
//       setAuthHeader(failedRequest.response.config);
//       return Promise.resolve();
//     }
//     /*const refreshToken = authServiceApi.getRefreshToken();
//         if (refreshToken) {
//             message.error('Votre session a expiré, veuillez vous reconnecter');
//         }
//         const disconnectElem = document.querySelector('.disconnect');
//         if (disconnectElem) {
//             disconnectElem.click();
//         }*/
//     return Promise.reject(`${result ? "Expired" : "No"} refresh token`);
//   })
// );

export default http;

import React from "react";
import { useState } from "react";
import Play from "./components/Play/Play";
import AppDetails from "./components/AppDetails/AppDetails";
import HomeContainer from "./components/HomeContainer/HomeContainer";
import Login from "./components/Login/Login";
import "./firebase";
import './assets/styles/app.scss';

// import { Plugins } from "@capacitor/core";
import { LoggedContext, OnlineContext } from "./context";

import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import CheckConnexion from "./components/checkConnexion/CheckConnexion";
import { colorPrimary } from "./utils/colors";
import { SplashScreen } from "./card/splashScreen";
import { ConnectLayout } from "./layouts/connected";
import { PublicLayout } from "./layouts/public";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// Plugins.App.addListener("backButton", () => {
//   Plugins.App.exitApp();
// });

const theme = createTheme({
  palette: {
    primary: {
      main: colorPrimary,
    },


    // primary: {
    //   main: colorPrimary,
    // },
    // secondary: {
    //   main: colorPrimary,
    // },
  },
});

function App() {
  const [logged, setLogged] = useState(false);
  const [appOnline, setAppOnline] = useState(true);
  const [open, setOpen] = React.useState(false);

  React.useEffect(()=>{
    setOpen(true);
    setTimeout(()=>{setOpen(false)},2000);
  },[])



  return (
    <BrowserRouter>
      <div style={{ maxWidth: "100%", height: "100vh", overflowX: "hidden" }}>
        <ThemeProvider theme={theme}>
          <CheckConnexion>
            <LoggedContext.Provider
              value={{ logged: logged, setLogged: setLogged }}
            >
              <OnlineContext.Provider
                value={{ online: appOnline, setOnline: setAppOnline }}
              >
                <Routes>
                  <Route element={<ConnectLayout />} path="/*" />
                  {/* <Route element={<PublicLayout />} path="home/*" /> */}
                  {/* <Route path="*" element={<NotFoundScreen />} /> */}
                </Routes>
              </OnlineContext.Provider>
            </LoggedContext.Provider>
          </CheckConnexion>
          <SplashScreen open={open} setOpen={setOpen} />
        </ThemeProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;

import { Grow, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PortableWifiOffIcon from "@material-ui/icons/PortableWifiOff";

const CheckConnexion = (props) => {
  const [onlineStatus, setOnlineStatus] = useState(true);

  useEffect(() => {
    window.addEventListener("offline", () => {
      setOnlineStatus(false);
    });
    window.addEventListener("online", () => {
      setOnlineStatus(true);
    });

    return () => {
      window.removeEventListener("offline", () => {
        setOnlineStatus(false);
      });
      window.removeEventListener("online", () => {
        setOnlineStatus(true);
      });
    };
  }, []);
  return onlineStatus ? (
    props.children
  ) : (
    <>
    {props.children}
    <Grow in={!onlineStatus}>
      <Paper
        style={{
          width: 270,
          height: 70,
          position: "fixed",
          left: 10,
          bottom: 0,
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          zIndex: 99999999,
        }}
      >
        <PortableWifiOffIcon fontSize="large" color="primary" />
        <Typography color="primary">Aucune connexion internet</Typography>
      </Paper>
    </Grow>
    </>
  );
};

export default CheckConnexion;

import React, { useState, useEffect } from "react";

import { getOrientation } from "./../Utils/Utils";
import PlaySection from "./PlaySection";
import { useLocation } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import ReactGA from "react-ga4";

const bgColor = window.localStorage.getItem("bgColor");
const cancelColor = window.localStorage.getItem("cancelColor");
const appOrientation = window.localStorage.getItem("orientation");

var universalOrientation;

if (appOrientation === "both") {
  universalOrientation = getOrientation();
} else if (appOrientation === "land") {
  universalOrientation = "landscape";
}else{
  universalOrientation = "landscape";
}

//A play Component returning an hideable PlaySection Component
function Play(props) {
  const [hide, setHide] = useState(false);
  const [url, setUrl] = React.useState("");
  const route = useLocation();
  const { trackEvent, trackPageView } = useMatomo()

  useEffect(() => {
    getOrientation() === universalOrientation ? setHide(false) : setHide(true);

    window.addEventListener("orientationchange", () => {
      getOrientation() === universalOrientation
        ? setHide(false)
        : setHide(true);
    });
    const src = window.localStorage.getItem("src");
    const name = window.localStorage.getItem("app-name");
    setUrl(src);
    // trackEvent({
    //   action:"use-app",category:"using",value:src,
    //   name:name,documentTitle:name,customDimensions:[{
    //     id:1,value:name,
    //   }]
    // });
    trackPageView({
      documentTitle:name,
      customDimensions:[{id:1,value:name}]
    });
    ReactGA.send({ hitType: "pageview", page: "/play", title: name });
  }, []);

  return (
    <div>
      <PlaySection
        hide={hide}
        src={url}
        cancelColor={cancelColor}
        bgColor={bgColor}
      />
    </div>
  );
}

export default Play;
